import client from '../httpClient';

/**
 * 【combination-setting001】組合せ設定一覧取得（エリアコード指定）
 * @param {number} areaCode
 */
const combinationSettingGetListApi = async (areaCode) => {
  const res = await client.get(`/combination-settings/${areaCode}`);
  return res.data;
};

export default combinationSettingGetListApi;
