import SelectStoreWindowContainers from '../../../containers/combinationSetting/selectStoreWindow/selectStoreWindowContainers';

function SelectStoreWindow(props) {
  const {
    open,
    setOpen,
    areaCode,
    initialStore,
    storeList,
    setStoreList,
  } = props;
  return (
    <SelectStoreWindowContainers
      open={open}
      setOpen={setOpen}
      areaCode={areaCode}
      initialStore={initialStore}
      storeList={storeList}
      setStoreList={setStoreList}
    />
  );
}

export default SelectStoreWindow;
