/* eslint-disable max-len */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import InfoRegisterChoice1 from './infoRegisterChoice1';
import InfoRegisterChoice3 from './infoRegisterChoice3';
import InfoRegisterCalendar from './infoRegisterCalendar';
import InfoRegisterRadio1 from './infoRegisterRadio1';
import InfoRegisterText from './infoRegisterText';
import InfoRegisterCheck1 from './infoRegisterCheck1';
import InfoRegisterLink from './infoRegisterLink';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      padding: '0 32px 32px',
    },
  },
  ttl: {
    [commonTheme.breakpoints.down('sm')]: {
      position: 'relative',
      padding: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: 8,
    },
    '&::after': {
      [commonTheme.breakpoints.down('sm')]: {
        content: '""',
        display: 'block',
        width: 7,
        height: 7,
        borderTop: `2px solid ${theme.palette.primaryColor}`,
        borderRight: `2px solid ${theme.palette.primaryColor}`,
        position: 'absolute',
        top: 20,
        right: 20,
        transform: 'rotate(-45deg)',
      },
    },
    '&.close': {
      '&::after': {
        [commonTheme.breakpoints.down('sm')]: {
          transform: 'rotate(135deg)',
        },
      },
    },
  },
  childwrap: {
    width: '100%',
    '&.close': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  itemwrap: {
    width: '100%',
    '&:not(:last-child)': {
      [commonTheme.breakpoints.down('sm')]: {
        marginBottom: 4,
      },
      [commonTheme.breakpoints.up('md')]: {
        marginBottom: 8,
      },
    },
  },
}));

function InfoRegisterGuidance(props) {
  const { input } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [displayControl, setDisplayControl] = useState(false);

  const box = {
    label: '案内情報',
    key: 'key1',
    items: [
      {
        type: 'calendar',
        key: 'dateAndTime',
        input: {
          label: '日時',
          required: true,
          onblur: input.datClose,
          state1: input.dat1,
          setState1: (e) => { return input.datCha1(e); },
          state2: input.dat2,
          setState2: (e) => { return input.datCha2(e); },
        },
      },
      {
        type: 'radio1',
        key: 'guidanceType',
        input: {
          label: '案内種別',
          required: true,
          state: input.typ,
          setState: (e) => { return input.typCha(e); },
        },
      },
      {
        type: 'check1',
        key: 'checked1',
        input: {
          disabled: input.ina1,
          state: input.che1,
        },
      },
      {
        type: 'link',
        key: 'customerName',
        input: {
          label: '顧客名',
          customerId: input.initialVal.customerId,
          required: true,
          state: input.customerName ? input.customerName : input.initialVal.customerName,
          setState: (e) => { return input.setState('customerName', e); },
          openHistory: input.openHistory,
        },
      },
      {
        type: 'choice3',
        key: 'suggestProducts',
        input: {
          label: '物件',
          required: true,
          disabled: false,
          state: input.product || '',
          setState: (e) => { return input.setState('suggestProducts', e); },
        },
      },
      {
        type: 'choice1',
        key: 'guidanceGetUserName',
        input: {
          label: '獲得者',
          required: true,
          state: input.guidanceGetUser.userName,
          setState: (e) => { return input.setState('guidanceGetUser', e); },
          setStateDivision: (e) => { return input.setStateDivision('guidanceGetDivision', e); },
          selectUser: input.guidanceGetUser.userName ? input.guidanceGetUser : input.responseHeader,
        },
      },
      {
        type: 'choice1',
        key: 'guidanceUserName',
        input: {
          label: '案内予定者',
          required: true,
          disabled: input.ina1,
          state: input.guidanceUser.userName,
          setState: (e) => { return input.setState('guidanceUser', e); },
          setStateDivision: (e) => { return input.setStateDivision('guidanceDivision', e); },
          selectUser: input.guidanceUser.userName ? input.guidanceUser : input.responseHeader,
        },
      },
      {
        type: 'text',
        key: 'memo',
        input: {
          label: 'メモ',
          required: false,
          state: input.memo,
          setState: (e) => { return input.setState('memo', e); },
        },
      },
    ],
  };

  const switchType = (val) => {
    const returnType = [];
    switch (val.type) {
      case 'text':
        return <InfoRegisterText values={val.input} />;
      case 'radio1':
        return <InfoRegisterRadio1 values={val.input} />;
      case 'check1':
        return <InfoRegisterCheck1 values={val.input} />;
      case 'calendar':
        return <InfoRegisterCalendar values={val.input} />;
      case 'choice1':
        return <InfoRegisterChoice1 values={val.input} />;
      case 'choice3':
        if ((input.isInitialRegistration && !input.reserveFlg) || (!input.isInitialRegistration && input.reserveFlg)) {
          val.input.disabled = true;
        }
        return <InfoRegisterChoice3 values={val.input} />;
      case 'link':
        return <InfoRegisterLink values={val.input} />;
      default:
        break;
    }
    return returnType;
  };

  const items = (val) => {
    const itemchild = [];
    val.map(i => itemchild.push(
      <Grid
        key={i.key}
        className={classes.itemwrap}
      >
        {switchType(i)}
      </Grid>,
    ));
    return itemchild;
  };

  return (
    <Grid className={classes.root}>
      <Grid className={classes.box}>
        <Typography
          className={`${baseClasses.title4} ${classes.ttl} ${displayControl && 'close'}`}
          onClick={() => { setDisplayControl(!displayControl); }}
        >
          {box.label}
        </Typography>
        <Grid
          container
          className={`${classes.childwrap} ${displayControl && 'close'}`}
        >
          {items(box.items)}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InfoRegisterGuidance;
