import { NO_CONTACT_DETAIL_REASON_CODE, NO_CONTACT_DETAIL_REASON_CODE_VIEW_ORDER } from '../../constants';

// 詳細検索
// ランク
export const rank = {
  menus: {
    5: '★5',
    4: '★4',
    3: '★3',
    2: '★2',
    1: '★1',
    0: '★なし',
  },
  title: 'ランク（課員）',
};

// 追客状況
export const customerStatus = {
  menus: {
    1: '追客中',
    2: '追わない',
    10: '契約',
    11: 'クレーム',
  },
  title: '追客状況',
};

// 会員状態
export const memberStatus = {
  menus: {
    0: '通常会員',
    1: 'プレミアム申請中',
    2: 'プレミアム会員',
    3: 'プレミアム否認',
    4: '非会員',
  },
  title: '会員状態',
};

// 希望条件Min
export const budget = {
  menus: {
    0: 0,
    1000: 1000,
    1500: 1500,
    2000: 2000,
    2500: 2500,
    3000: 3000,
    3500: 3500,
    4000: 4000,
    4500: 4500,
    5000: 5000,
    5500: 5500,
    6000: 6000,
    6500: 6500,
    7000: 7000,
    7500: 7500,
    8000: 8000,
    8500: 8500,
    9000: 9000,
    9500: 9500,
    10000: '10000以上',
  },
  title: '金額（万円）',
};

// 希望条件Max
export const budgetMax = {
  menus: {
    0: 0,
    1000: 1000,
    1500: 1500,
    2000: 2000,
    2500: 2500,
    3000: 3000,
    3500: 3500,
    4000: 4000,
    4500: 4500,
    5000: 5000,
    5500: 5500,
    6000: 6000,
    6500: 6500,
    7000: 7000,
    7500: 7500,
    8000: 8000,
    8500: 8500,
    9000: 9000,
    9500: 9500,
  },
  title: '金額（万円）',
};

// 広さ
export const breadth = {
  menus: {
    40: '40㎡',
    50: '50㎡',
    60: '60㎡',
    70: '70㎡',
    80: '80㎡',
    90: '90㎡',
    100: '100㎡',
    150: '150㎡',
    200: '200㎡',
  },
  title: '広さ（平米）',
};

// 年収
export const income = {
  menus: {
    300: '300万円',
    400: '400万円',
    500: '500万円',
    600: '600万円',
    700: '700万円',
    800: '800万円',
    900: '900万円',
    1000: '1000万円',
    1500: '1500万円',
    2000: '2000万円',
  },
  title: '年収',
};

// 電話未対応
export const phoneHistorySelect = {
  menus: {
    1: '今日',
    2: '昨日',
    3: '一昨日',
    4: '～7日',
    5: '～14日',
    6: '～30日',
    7: '～過去全て',
  },
  title: 'TEL未対応',
};

export const mailSendHistorySelect = {
  menus: {
    1: '今日',
    2: '昨日',
    3: '一昨日',
    4: '～7日',
    5: '～14日',
    6: '～30日',
    7: '～過去全て',
  },
  title: 'Mail未送信',
};
export const yesno = {
  menus: {
    0: 'なし',
    1: 'あり',
  },
  title: 'あり/なし',
};

export const floorPlan = {
  menus: {
    4: '2LDK以下',
    8: '3LDK',
    16: '4LDK',
    32: '5LDK以上',
  },
  title: '間取り',
};

export const holiday = {
  menus: {
    1: '月曜日',
    2: '火曜日',
    3: '水曜日',
    4: '木曜日',
    5: '金曜日',
    6: '土曜日',
    7: '日曜日',
  },
  title: 'お休み',
};

export const isMail = {
  menus: {
    0: 'ダメ全部NG',
    1: '個別のみOK',
    3: '一斉・個別OK',
    5: '代行も全てOK',
  },
  title: 'メール許可',
};

export const supportKeyword = {
  menus: {
    1: '件名',
    2: '対応内容',
    4: '対応者',
  },
  title: 'キーワードの検索の範囲',
};

export const nonContact = {
  menus: NO_CONTACT_DETAIL_REASON_CODE,
  title: '追わない理由',
};

// オブジェクトの表示順がキーの昇順になってしまうため、別途用意
export const nonContactOrder = NO_CONTACT_DETAIL_REASON_CODE_VIEW_ORDER;

export const nonContactKeyword = {
  menus: {
    1: '購入できない理由',
    2: 'お客様が探していなかった理由',
    3: '要望エリア（当時のOHDエリア以外）',
    4: '他OHDにはまらない理由',
  },
  title: 'キーワードの検索の範囲',
};

export const keywords = {
  menus: {
    1: '顧客名',
    2: '住所',
    3: '電話番号',
    4: 'メール',
    5: '希望学区1',
    6: '希望学区2',
    7: 'メモ',
    8: '重視するポイント',
    9: '固定メモ',
  },
  title: 'キーワード検索の範囲',
};

export const canCallTime = {
  menus: {
    午前中: '午前中',
    '12時～13時': '12時～13時',
    '13時～15時': '13時～15時',
    '15時～18時': '15時～18時',
    '18時～19時': '18時～19時',
    '19時～20時': '19時～20時',
    '20時～21時': '20時～21時',
  },
  title: '電話が繋がりやすい時間帯',
};

export const canCallTimeNow = {
  menus: {
    8: '午前中',
    9: '午前中',
    10: '午前中',
    11: '午前中',
    12: '12時～13時',
    13: '13時～15時',
    14: '13時～15時',
    15: '15時～18時',
    16: '15時～18時',
    17: '15時～18時',
    18: '18時～19時',
    19: '19時～20時',
    20: '20時～21時',
  },
  title: '電話が繋がりやすい時間帯',
};

export const lastProductBrowsedAt = {
  title: '最終WEB物件閲覧履歴日',
  menus: {
    1: '30日以内を除外',
    2: '60日以内を除外',
    3: '90日以内を除外',
  },
};

export const lastDocumentRequestedAt = {
  title: '最終資料請求日',
  menus: {
    1: '7日以内を除外',
    2: '14日以内を除外',
    3: '30日以内を除外',
  },
};

export const lastVisitReservedAt = {
  title: '最終見学予約日',
  menus: {
    1: '7日以内を除外',
    2: '14日以内を除外',
    3: '30日以内を除外',
  },
};

// searchType
export const SEARCH_TYPE_CODE = {
  menus: {
    0: 'OR検索に含める',
    1: 'AND検索に含める',
  },
  title: '',
};

// メール開封履歴
export const MAIL_OPEN_HISTORY_CODE = {
  menus: {
    0: '開封なし',
    1: '今日',
    2: '昨日',
    3: '一昨日',
    4: '～7日',
    5: '～14日',
    6: '～30日',
    7: '～過去全て',
  },
  title: 'メール開封',
};
