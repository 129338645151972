import client from '../httpClient';

/**
 * 【combination-setting003】組み合わせ設定削除
 * @param {{
 * areaCode: number;
 * combinationId: number;
 * }} data
 */
const combinationSettingDeleteApi = async (params) => {
  const res = await client.delete('/combination-settings', { params });
  return res.data;
};

export default combinationSettingDeleteApi;
