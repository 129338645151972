import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Select,
  MenuItem,
  List,
  ListItem,
} from '@material-ui/core';
import Rating from '@material-ui/lab/Rating';
import BlockIcon from '@material-ui/icons/Block';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import Icon from '@material-ui/core/Icon';
import commonStyles from '../../../styles';
import commonTheme from '../../../styles/theme';
import { changeConfirmMessage } from '../../../../store/eleCommon/customConfirmMessage';
import {
  rankSelect,
} from '../../../../constants/customerMain';
import {
  LEAVE_STATUS,
  TEL_STATUS_CODE,
  EMAIL_STATUS_CODE,
  NO_CONTACT_DETAIL_REASON_CODE,
  NO_CONTACT_DETAIL_REASON_CODE_VIEW_ORDER,
  CUSTOMER_STATUS_CONTRACT,
  CUSTOMER_STATUS_NOT_PURSUING,
  CUSTOMER_STATUS_CLAIM,
  EMAIL_STATUS_AUTO,
  CUSTOMER_STATUS_PURSUING,
} from '../../../../constants';
import ClaimRegister from '../../claimRegister';
import DoNotChaseReason from '../../doNotChaseReason';
import LiftWindow from '../../../customerMain/parts/liftWindow';
import ContractInformation from '../../../customerMain/contractInformation';
import { changeAlertMessage } from '../../../../store/eleCommon/customAlertMessage';
import { useStore } from '../../../../hooks';
import Calendar from '../../../common/calendar';
import { TextBaseField } from '../../../eleCommon/validation';
import { convertForm1 } from '../../../../commonFunction/convertors';
import { LEADERS_MEMO_USE } from '../../../../constants/userRole';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 8,
    marginBottom: 8,
  },
  inHouse: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    marginTop: 8,
    width: '100%',
  },
  inHouseInfoBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: 8,
    },
  },
  inHouseInfoBlock1: {
    width: 260,
  },
  inHouseInfoBlock2: {
    width: 528,
  },
  inHouseInfoName: {
    display: 'flex',
    alignItems: 'center',
  },
  inHouseInfoName1: {
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
    [commonTheme.breakpoints.up('md')]: {
      marginRight: 4,
    },
  },
  inHouseInfoNameText: {
    color: '#c8c8c8',
    [commonTheme.breakpoints.up('md')]: {
      fontSize: 13,
    },
  },
  inHouseInfoSelect: {
    width: '100%',
  },
  inHouseInfoStatus1: {
    padding: 8,
  },
  inHouseInfoStatus2: {
    pointerEvents: 'none',
    background: '#C8C8C8',
    padding: 8,
    '& button': {
      background: '#DEDEDE',
      '& > span': {
        color: '#AFAFAF',
      },
    },
  },
  inHouseInfoBtn: {
    padding: '4px 10px',
    '& > span': {
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
    },
  },
  formControlLabel: {
    display: 'flex',
    alignItems: 'center',
    margin: '0 0 0 5px',
    [commonTheme.breakpoints.down('sm')]: {
      margin: 0,
      width: '100%',
      justifyContent: 'space-between',
    },
    '& > span': {
      display: 'block',
      fontFamily: 'Roboto',
      fontSize: 13,
      lineHeight: 1.6,
      letterSpacing: 0,
      padding: 0,
      marginLeft: 4,
      '&.Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& > .MuiIconButton-label': {
        background: '#fff',
        width: 18,
        height: 18,
        borderRadius: 4,
      },
    },
    '& + span': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
  textField: {
    display: 'block',
    '& > div::before': {
      [commonTheme.breakpoints.up('md')]: {
        borderBottom: '1px solid transparent',
      },
    },
    '& > div::after': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& > div:hover:not(.Mui-disabled):before': {
      borderBottom: `2px solid ${theme.palette.primaryColor}`,
    },
    '& label': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      transformOrigin: 'top left',
      fontSize: 13,
      lineHeight: 1.2,
      transform: 'translate(0, 2px) scale(1)',
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& input': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& textarea': {
      fontFamily: 'Roboto',
      fontSize: 16,
      zoom: 0.81,
      lineHeight: 1.6,
      textAlign: 'left',
      fontWeight: '700',
      padding: 0,
      height: 'auto',
      letterSpacing: 0,
    },
    '& div': {
      margin: 0,
      width: '100%',
    },
  },
  calendar: {
    display: 'block',
    border: 'none',
    padding: 0,
    '& > p': {
      fontFamily: 'Roboto',
      color: '#333',
      right: 0,
      margin: '0 auto',
      textAlign: 'left',
      fontSize: 13,
      lineHeight: 1.2,
      position: 'relative',
      letterSpacing: 0,
      [commonTheme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    '& > label': {
      margin: 0,
      width: '100%',
      textAlign: 'left',
      [commonTheme.breakpoints.down('sm')]: {
        textAlign: 'right',
      },
      '& > span': {
        padding: '8px 0',
        fontFamily: 'Roboto',
        color: '#333',
        fontSize: 13,
        lineHeight: 1.6,
        fontWeight: '700',
      },
    },
  },
  inHouseRankCheck: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 8,
  },
  inHouseRank: {
    background: '#fff',
    border: '1px solid #C8C8C8',
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    width: 270,
  },
  star: {
    position: 'relative',
    width: 146,
    height: 20,
    margin: '-6px 0 0 10px',
  },
  starLbl: {
    background: '#fff',
    pointerEvents: 'none',
    height: '100%',
    minHeight: 23,
    [commonTheme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
    [commonTheme.breakpoints.up('md')]: {
      position: 'absolute',
      zIndex: '2',
      top: '-2px',
      left: 0,
      width: '100%',
    },
  },
  starSelect: {
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    minHeight: 20,
    display: 'flex',
    alignItems: 'center',
  },
  inHouseTf: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  inHouseTfLbl: {
    whiteSpace: 'nowrap',
  },
  inHouseTfInput: {
    margin: '-3px 0 0 8px',
    width: '100%',
  },
  claim: {
    pointerEvents: 'none',
    background: '#C8C8C8',
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 8px 16px 32px',
      marginBottom: 4,
    },
  },
  // モーダル
  paper: {
    width: 515,
    backgroundColor: '#fff',
    boxShadow: '0px 15px 12px rgba(51, 51, 51, 0.22), 0px 19px 38px rgba(51, 51, 51, 0.3)',
    borderRadius: 4,
    padding: '24px 24px 18px',
  },
  paperTtl: {
    textAlign: 'center',
    margin: '0 0 24px',
  },
  paperBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paperBtn: {
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    margin: '0 60px',
  },
  formControl: {
    width: '100%',
    '& div::before': {
      borderBottom: '1px solid transparent',
    },
  },
  selectEmpty: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    '& div': {
      padding: '0 24px 0 0',
      marginTop: 1,
    },
  },
  // SP
  ttl: {
    padding: 16,
  },
  list: {
    background: '#fff',
    padding: '0 0 0 16px',
    overflow: 'hidden',
    '&.disabled': {
      pointerEvents: 'none',
      background: 'none',
    },
  },
  listRank: {
    marginTop: 4,
  },
  listItem: {
    position: 'relative',
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listLbl: {
    textTransform: 'none',
  },
  listVal: {
    textTransform: 'none',
  },
  selectBtn: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&.select': {
      color: theme.palette.primaryColor,
    },
    '& .MuiButton-label': {
      display: 'block',
      paddingLeft: 16,
    },
  },
  selectBtn2: {
    width: '100%',
    padding: '18px 0',
    textAlign: 'left',
    borderBottom: '1px solid #F3F3F3',
    '&:nth-child(1)': {
      color: theme.palette.primaryColor,
    },
    '&:nth-child(2)': {
      color: '#D83420',
    },
    '&:nth-child(3)': {
      color: '#27AE60',
    },
    '&:nth-child(4)': {
      color: '#D83420',
    },
    '& .MuiSvgIcon-root': {
      marginRight: 1,
    },
    '& .material-icons': {
      marginRight: 1,
    },
    '& .MuiButton-label': {
      paddingLeft: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  statusWrap: {
    padding: '0 0 0 16px',
    background: '#fff',
  },
  status: {
    display: 'flex',
    alignItems: 'center',
    [commonTheme.breakpoints.down('sm')]: {
      background: '#fff',
      justifyContent: 'space-between',
      borderBottom: '1px solid #F3F3F3',
    },
    '& .MuiButton-label': {
      [commonTheme.breakpoints.down('sm')]: {
        display: 'flex',
        justifyContent: 'flex-start',
      },
    },
  },
  status1: {
    [commonTheme.breakpoints.up('md')]: {
      color: theme.palette.primaryColor,
      padding: '4px 10px',
    },
  },
  status2: {
    [commonTheme.breakpoints.up('md')]: {
      color: '#D83420',
      padding: '10px',
    },
  },
  status3: {
    [commonTheme.breakpoints.up('md')]: {
      color: '#D83420',
      padding: '0 15px',
    },
  },
  status4: {
    [commonTheme.breakpoints.up('md')]: {
      color: '#27AE60',
      padding: '9px',
    },
  },
  statusBtn: {
    display: 'inline-flex',
    [commonTheme.breakpoints.down('sm')]: {
      padding: 16,
    },
  },
  statusBtn1: {
    [commonTheme.breakpoints.down('sm')]: {
      color: theme.palette.primaryColor,
      width: '100%',
    },
  },
  statusBtn2: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#D83420',
      width: '100%',
    },
  },
  statusBtn3: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#D83420',
      width: '72%',
    },
  },
  statusBtn4: {
    [commonTheme.breakpoints.down('sm')]: {
      color: '#27AE60',
      width: '72%',
    },
  },
  statusBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: 90,
  },
  statusLbl: {
    background: '#fff',
    position: 'absolute',
    zIndex: '2',
    top: 0,
    left: 0,
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    minHeight: 23,
  },
  statusSelect: {
    position: 'absolute',
    zIndex: '1',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  statusIcon: {
    fontSize: 24,
  },
  statusVal: {
    whiteSpace: 'nowrap',
  },
  statusTtl: {
    fontSize: 52,
    lineHeight: 1.2,
    fontWeight: '700',
  },
  statusTxt: {
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    width: '100%',
    textAlign: 'left',
    color: '#333',
    [commonTheme.breakpoints.down('sm')]: {
      width: '60%',
      marginLeft: 24,
    },
  },
  button1: {
    background: '#D83420',
    color: '#fff',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    padding: '4px 10px',
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
  },
  button2: {
    color: '#fff',
    borderRadius: '4px',
    fontFamily: 'Roboto',
    fontSize: 13,
    fontWeight: '700',
    lineHeight: 1.6,
    letterSpacing: 0,
    marginLeft: 8,
    padding: '4px 10px',
    minWidth: 'auto',
    [commonTheme.breakpoints.down('sm')]: {
      marginRight: 16,
    },
  },
}));

export default function CustomerDetailInHouse(props) {
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const menus1 = Object.keys(LEAVE_STATUS);
  const menus2 = Object.keys(TEL_STATUS_CODE);
  const menus3 = Object.keys(EMAIL_STATUS_CODE);
  const menus4 = NO_CONTACT_DETAIL_REASON_CODE_VIEW_ORDER.map(String);
  const rating = [
    { no: 5 },
    { no: 4 },
    { no: 3 },
    { no: 2 },
    { no: 1 },
    { no: 0 },
  ];

  const {
    input,
  } = props;

  const { responseHeader } = useStore();
  const { roleIds } = responseHeader;

  // チェックボックス 顧客紹介対象
  const isCustomerIntroductionInitial = () => {
    switch (input.detail.isCustomerIntroduction) {
      case 1:
        return true;
      default:
        return false;
    }
  };
  const [checked, setChecked] = useState(isCustomerIntroductionInitial());
  const isCustomerIntroductionChange = (e) => {
    setChecked(e.target.checked);
    switch (e.target.checked) {
      case true:
        // 変更した値をセット
        input.setDetail({ ...input.detail, [e.target.name]: 1 });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: 1 });
        break;
      default:
        // 変更した値をセット
        input.setDetail({ ...input.detail, [e.target.name]: 0 });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: 0 });
    }
  };

  // 追わない理由ウインドウ用
  const initial = {
    noContactDetailReasonCode: 0,
    searchArea: '',
    reasonNotOhd: '',
    searchStartAt: '',
    reasonNotSearch: '',
    reasonNotBuy: '',
  };
  const [userData, setUserData] = useState(initial);

  // 追わない customerStatusCode:2
  // [GA092]画面表示用
  const [open1, setOpen1] = useState(false);
  // クレーム [GA018]画面
  const [open2, setOpen2] = useState(false);
  // モーダル用
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  // 解除申請
  const [open13, setOpen13] = useState(false);
  // 履歴
  const [open14, setOpen14] = useState(false);
  // ランク
  const [open15, setOpen15] = useState(false);
  const handleOpen1 = () => {
    setOpen1(true);
  };
  const handleOpen2 = () => {
    setOpen2(true);
  };
  const handleOpen3 = () => {
    setOpen3(true);
  };
  const handleOpen4 = () => {
    setOpen4(true);
  };
  const handleOpen13 = () => {
    setOpen13(true);
  };
  const handleOpen14 = () => {
    setOpen14(true);
  };
  const handleOpen15 = () => {
    setOpen15(true);
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const handleClose2 = () => {
    setOpen2(false);
  };
  const handleClose3 = () => {
    setOpen3(false);
  };
  const handleClose4 = () => {
    setOpen4(false);
  };
  const handleClose13 = () => {
    setOpen13(false);
  };
  const handleClose14 = () => {
    setOpen14(false);
  };
  const handleClose15 = () => {
    setOpen15(false);
  };

  // 値の退避用のステート
  const [eValue, setEValue] = useState('');
  const [eName, setEName] = useState('');
  const [eName2, setEName2] = useState('');
  const [eValue2, setEValue2] = useState('');
  const prevEmailStatusRef = useRef(input.detail.emailStatusCode);

  // 初期化
  const initialFunction = () => {
    setEName('');
    setEValue('');
    setEName2('');
    setEValue2('');
  };

  // ランク
  const [star, setStar] = useState(input.detail.userLevel);
  const userLevelChange = (e) => {
    const userLevel = Number(e.target.value);

    if (star !== userLevel) {
      const array = [0, 5];
      switch (userLevel) {
        case 2:
          // 表示を変更
          setStar(userLevel);
          if (!array.includes(input.detail.emailStatusCode)) {
            // 変更した値をセット
            input.setDetail({ ...input.detail, userLevel, emailStatusCode: EMAIL_STATUS_AUTO });
            // 更新用の値をセット
            input.dataInput.setData({
              ...input.dataInput.data,
              userLevel,
              emailStatusCode: EMAIL_STATUS_AUTO,
            });
            // 保存用の値をセット
            prevEmailStatusRef.current = EMAIL_STATUS_AUTO;
            dispatch(changeAlertMessage({
              msgList: [], // 必須
              title: 'メール許可を「代行もすべてOK」に変更しました。', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
              isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
            }));
          } else {
            // 変更した値をセット
            input.setDetail({ ...input.detail, userLevel });
            // 更新用の値をセット
            input.dataInput.setData({ ...input.dataInput.data, userLevel });
          }
          break;
        case 3:
          if (!array.includes(input.detail.emailStatusCode)) {
            dispatch(changeConfirmMessage({
              title: 'メール許可を「代行もすべてOK」に変更しますか？',
              msgList: ['「代行もすべてOK」に変更すると、自動で追客されます。'],
              buttons: [
                {
                  label: '代行も全てOK にする',
                  set: () => {
                    // 表示を変更
                    setStar(userLevel);
                    // 変更した値をセット
                    input.setDetail({
                      ...input.detail,
                      userLevel,
                      emailStatusCode: EMAIL_STATUS_AUTO,
                    });
                    // 更新用の値をセット
                    input.dataInput.setData({
                      ...input.dataInput.data,
                      userLevel,
                      emailStatusCode: EMAIL_STATUS_AUTO,
                    });
                  },
                  classes: baseClasses.buttonsSecondary,
                },
                {
                  label: 'しない',
                  set: () => {
                    // 表示を変更
                    setStar(userLevel);
                    // 変更した値をセット
                    input.setDetail({ ...input.detail, userLevel });
                    // 更新用の値をセット
                    input.dataInput.setData({
                      ...input.dataInput.data,
                      userLevel,
                    });
                  },
                  classes: baseClasses.buttonsPrimary,
                },
              ],
            }));
          } else {
            // 表示を変更
            setStar(userLevel);
            // 変更した値をセット
            input.setDetail({ ...input.detail, userLevel });
            // 更新用の値をセット
            input.dataInput.setData({ ...input.dataInput.data, userLevel });
          }
          break;
        default:
          // 表示を変更
          setStar(userLevel);
          // 変更した値をセット
          input.setDetail({ ...input.detail, userLevel });
          // 更新用の値をセット
          input.dataInput.setData({ ...input.dataInput.data, userLevel });
          break;
      }
    }
  };

  // 顧客ステータス
  const menusKeys = Object.keys(LEAVE_STATUS);
  const [code, setCode] = useState(input.detail.customerStatusCode);

  // SP・PCでの分岐用
  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);
  let contents;

  // フォーカスが外れた時
  const onBlur = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, [e.target.name]: e.target.value });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, [e.target.name]: e.target.value });
  };

  // 決定押下時
  const onDecision = () => {
    if (eName2 === '') {
      // 変更した値をセット SP
      input.setDetail({ ...input.detail, [eName]: eValue });
      // 更新用の値をセット SP
      input.dataInput.setData({ ...input.dataInput.data, [eName]: eValue });
    } else {
      // 変更した値をセット SP
      input.setDetail({ ...input.detail, [eName]: eValue, [eName2]: eValue2 });
      // 更新用の値をセット SP
      input.dataInput.setData({ ...input.dataInput.data, [eName]: eValue, [eName2]: eValue2 });
    }
    // 初期化
    initialFunction();
  };

  // 検討再開時期
  const dateChange = (data) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, searchStartAt: data });
    // 更新用の値をセット
    input.dataInput.setData({
      ...input.dataInput.data,
      searchStartAt: data !== '' ? data + ' 00:00:00' : null,
    });
  };

  // プルダウンの値を変更
  const customerStatusSet2 = () => {
    // 変更した値をセット
    input.setDetail(
      {
        ...input.detail,
        customerStatusCode: CUSTOMER_STATUS_NOT_PURSUING,
        noContactDetailReasonCode: userData.noContactDetailReasonCode,
      },
    );
    // 更新用の値をセット
    switch (userData.noContactDetailReasonCode) {
      case 4:
        input.dataInput.setData(
          {
            ...input.dataInput.data,
            customerStatusCode: CUSTOMER_STATUS_NOT_PURSUING,
            noContactDetailReasonCode: userData.noContactDetailReasonCode,
            reasonNotBuy: userData.reasonNotBuy,
          },
        );
        break;
      case 6:
        if (userData.searchStartAt === '') {
          input.dataInput.setData(
            {
              ...input.dataInput.data,
              customerStatusCode: CUSTOMER_STATUS_NOT_PURSUING,
              noContactDetailReasonCode: userData.noContactDetailReasonCode,
              searchStartAt: userData.searchStartAt,
              reasonNotSearch: userData.reasonNotSearch,
            },
          );
        } else {
          input.dataInput.setData(
            {
              ...input.dataInput.data,
              customerStatusCode: CUSTOMER_STATUS_NOT_PURSUING,
              noContactDetailReasonCode: userData.noContactDetailReasonCode,
              searchStartAt: userData.searchStartAt + ' 00:00:00',
              reasonNotSearch: userData.reasonNotSearch,
            },
          );
        }
        break;
      case 7:
        input.dataInput.setData(
          {
            ...input.dataInput.data,
            customerStatusCode: CUSTOMER_STATUS_NOT_PURSUING,
            noContactDetailReasonCode: userData.noContactDetailReasonCode,
            searchArea: userData.searchArea,
            reasonNotOhd: userData.reasonNotOhd,
          },
        );
        break;
      default:
        input.dataInput.setData(
          {
            ...input.dataInput.data,
            customerStatusCode: CUSTOMER_STATUS_NOT_PURSUING,
            noContactDetailReasonCode: userData.noContactDetailReasonCode,
          },
        );
        break;
    }
    setCode(2);
  };

  // 確認ウインドウ 契約
  const confirmOpen = () => {
    dispatch(changeConfirmMessage({
      title: '',
      msgList: ['顧客ステータスを契約にして保存すると、', '契約済み顧客となりますが、本当によろしいですか？'],
      buttons: [
        {
          label: 'CANCEL',
          set: () => {},
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'OK',
          set: () => {
            // 変更した値をセット
            input.setDetail({ ...input.detail, customerStatusCode: CUSTOMER_STATUS_CONTRACT });
            // 更新用の値をセット
            input.dataInput.setData({
              ...input.dataInput.data,
              customerStatusCode: CUSTOMER_STATUS_CONTRACT,
            });
            setCode(CUSTOMER_STATUS_CONTRACT);
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  // プルダウンの値を変更
  const customerStatusSet11 = () => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, customerStatusCode: CUSTOMER_STATUS_CLAIM });
    setCode(11);
  };

  const customerStatusCodeBlur = (e) => {
    if (e.target.value !== input.detail.customerStatusCode) {
      input.setDetail({ ...input.detail, customerStatusCode: e.target.value });
    }
  };

  // 確認ウインドウ 追わない
  const confirmOpenClaim = () => {
    dispatch(changeConfirmMessage({
      title: 'クレームはありませんでしたか？',
      msgList: ['クレームをいただいた場合はクレーム登録を行ってください。'],
      buttons: [
        {
          label: 'クレーム登録',
          set: () => {
            handleOpen2();
          },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'はい',
          set: () => {
            // [GA092]画面を呼び出す
            handleOpen1();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const confirmOpenAuto = () => {
    dispatch(changeConfirmMessage({
      title: 'まずは自動追客対象にしませんか？',
      msgList: ['メールの許可を「代行も全てOK」に変更すると設定できます。'],
      buttons: [
        {
          label: '代行も全てOK にする',
          set: () => {
            // メールステータスを変更
            input.setDetail({ ...input.detail, emailStatusCode: EMAIL_STATUS_AUTO });
          },
          classes: baseClasses.buttonsSecondary,
        },
        {
          label: 'しない',
          set: () => {
            confirmOpenClaim();
          },
          classes: baseClasses.buttonsPrimary,
        },
      ],
    }));
  };

  const customerStatusCodeChange = (e) => {
    switch (Number(e.target.value)) {
      case CUSTOMER_STATUS_NOT_PURSUING:
        // 追わない
        confirmOpenAuto();
        break;
      case CUSTOMER_STATUS_CONTRACT:
        // 契約済み
        confirmOpen();
        break;
      case CUSTOMER_STATUS_CLAIM:
        // クレーム
        // [GA018]画面を呼び出す
        handleOpen2();
        break;
      default:
        setCode(e.target.value);
        // 変更した値をセット
        input.setDetail({ ...input.detail, customerStatusCode: e.target.value });
        // 更新用の値をセット
        input.dataInput.setData({ ...input.dataInput.data, customerStatusCode: e.target.value });
    }
  };

  // 項目選択時に更新 SP
  const onSelect1 = (val) => {
    if (Number(val) === CUSTOMER_STATUS_NOT_PURSUING) {
      // 追わない
      confirmOpenAuto();
    } else if (Number(val) === CUSTOMER_STATUS_CLAIM) {
      // クレーム
      handleOpen2();
    } else {
      setCode(Number(val));
      // 変更した値をセット
      input.setDetail({ ...input.detail, customerStatusCode: val });
      // 更新用の値をセット
      input.dataInput.setData({ ...input.dataInput.data, customerStatusCode: val });
    }
  };
  const onSelect2 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, telStatusCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, telStatusCode: val });
  };
  const onSelect3 = (val) => {
    if (Number(input.detail.userLevel) === 2) {
      switch (Number(val)) {
        case 0:
          // 変更した値をセット
          input.setDetail({ ...input.detail, emailStatusCode: val });
          // 更新用の値をセット
          input.dataInput.setData({ ...input.dataInput.data, emailStatusCode: val });
          break;
        case 5:
          // 変更した値をセット
          input.setDetail({ ...input.detail, emailStatusCode: val });
          // 更新用の値をセット
          input.dataInput.setData({ ...input.dataInput.data, emailStatusCode: val });
          break;
        default:
          dispatch(changeAlertMessage({ msgList: ['メール許可を「個別のみOK」「個別・一斉OK」にする場合は、', 'ランクを変更してください。'] }));
          break;
      }
    } else {
      // 変更した値をセット
      input.setDetail({ ...input.detail, emailStatusCode: val });
      // 更新用の値をセット
      input.dataInput.setData({ ...input.dataInput.data, emailStatusCode: val });
    }
  };
  const onSelect4 = (val) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, noContactDetailReasonCode: val });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, noContactDetailReasonCode: val });
  };
  const onSelect5 = (val) => {
    if (input.detail.userLevel !== val) {
      const array = [0, 5];
      switch (val) {
        case 2:
          // 表示を変更
          setStar(val);
          if (!array.includes(input.detail.emailStatusCode)) {
            // 変更した値をセット
            input.setDetail({
              ...input.detail,
              userLevel: val,
              emailStatusCode: EMAIL_STATUS_AUTO,
            });
            // 更新用の値をセット
            input.dataInput.setData({
              ...input.dataInput.data,
              userLevel: val,
              emailStatusCode: EMAIL_STATUS_AUTO,
            });
            dispatch(changeAlertMessage({
              msgList: [], // 必須
              title: 'メール許可を「代行もすべてOK」に変更しました。', // 変更時のみ。設定しなければデフォルトの「入力された内容に誤りがあります」
              isNotice: true, // エラーではない場合。設定しなければ文字色、背景が赤くなる
            }));
          } else {
            // 変更した値をセット
            input.setDetail({ ...input.detail, userLevel: val });
            // 更新用の値をセット
            input.dataInput.setData({ ...input.dataInput.data, userLevel: val });
          }
          break;
        case 3:
          if (!array.includes(input.detail.emailStatusCode)) {
            dispatch(changeConfirmMessage({
              title: 'メール許可を「代行もすべてOK」に変更しますか？',
              msgList: ['「代行もすべてOK」に変更すると、自動で追客されます。'],
              buttons: [
                {
                  label: '代行も全てOK にする',
                  set: () => {
                    // 表示を変更
                    setStar(val);
                    // 変更した値をセット
                    input.setDetail({
                      ...input.detail,
                      userLevel: val,
                      emailStatusCode: EMAIL_STATUS_AUTO,
                    });
                    // 更新用の値をセット
                    input.dataInput.setData({
                      ...input.dataInput.data,
                      userLevel: val,
                      emailStatusCode: EMAIL_STATUS_AUTO,
                    });
                  },
                  classes: baseClasses.buttonsSecondary,
                },
                {
                  label: 'しない',
                  set: () => {
                    // 表示を変更
                    setStar(val);
                    // 変更した値をセット
                    input.setDetail({ ...input.detail, userLevel: val });
                    // 更新用の値をセット
                    input.dataInput.setData({
                      ...input.dataInput.data,
                      userLevel: val,
                    });
                  },
                  classes: baseClasses.buttonsPrimary,
                },
              ],
            }));
          } else {
            // 表示を変更
            setStar(val);
            // 変更した値をセット
            input.setDetail({ ...input.detail, userLevel: val });
            // 更新用の値をセット
            input.dataInput.setData({ ...input.dataInput.data, userLevel: val });
          }
          break;
        default:
          // 表示を変更
          setStar(val);
          // 変更した値をセット
          input.setDetail({ ...input.detail, userLevel: val });
          // 更新用の値をセット
          input.dataInput.setData({ ...input.dataInput.data, userLevel: val });
          break;
      }
    }
  };

  // 電話ステータス メール許可 理由 ランク PC
  const selectChange1 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, telStatusCode: e.target.value });
  };
  const selectChange2 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, emailStatusCode: e.target.value });
    if (Number(input.detail.userLevel) === 2) {
      switch (Number(e.target.value)) {
        case 0:
        case 5:
          // 変更した値をセット
          input.setDetail({ ...input.detail, emailStatusCode: e.target.value });
          // 保存用の値をセット
          prevEmailStatusRef.current = e.target.value;
          break;
        default:
          dispatch(changeAlertMessage({ msgList: ['メール許可を「個別のみOK」「個別・一斉OK」にする場合は、', 'ランクを変更してください。'] }));
          break;
      }
    } else {
      // 変更した値をセット
      input.setDetail({ ...input.detail, emailStatusCode: e.target.value });
    }
  };
  const selectChange3 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, noContactDetailReasonCode: e.target.value });
  };
  const selectBlur1 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, telStatusCode: e.target.value });
  };
  const selectBlur2 = (e) => {
    // selectChange2内だとうまく動かないためこちらで処理
    const array = [0, 5];
    if (Number(input.detail.userLevel) === 2 && !array.includes(Number(e.target.value))) {
      input.setDetail({ ...input.detail, emailStatusCode: prevEmailStatusRef.current });
    } else {
      // 更新用の値をセット
      input.dataInput.setData({ ...input.dataInput.data, emailStatusCode: e.target.value });
    }
  };
  const selectBlur3 = (e) => {
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, noContactDetailReasonCode: e.target.value });
  };
  const selectBlur4 = (e) => {
    // 変更した値をセット
    input.setDetail({ ...input.detail, userLevel: e.target.value });
    // 更新用の値をセット
    input.dataInput.setData({ ...input.dataInput.data, userLevel: e.target.value });
  };
  const customerSelectList = {
    telStatusCodeItem: {
      type: 'select',
      obj: {
        menus: TEL_STATUS_CODE,
      },
      state: input.detail.telStatusCode,
      set: selectBlur1,
      change: selectChange1,
    },
    emailStatusCodeItem: {
      type: 'select',
      obj: {
        menus: EMAIL_STATUS_CODE,
      },
      state: input.detail.emailStatusCode,
      set: selectBlur2,
      change: selectChange2,
    },
    nonContactReasonCodeItem: {
      type: 'select',
      obj: {
        menus: NO_CONTACT_DETAIL_REASON_CODE,
      },
      objOrder: NO_CONTACT_DETAIL_REASON_CODE_VIEW_ORDER,
      state: input.detail.noContactDetailReasonCode,
      set: selectBlur3,
      change: selectChange3,
      disabled: code !== CUSTOMER_STATUS_NOT_PURSUING,
    },
    rankItem: {
      type: 'selectBack',
      obj: rankSelect,
      state: star,
      change: userLevelChange,
      set: selectBlur4,
    },
  };

  const getStatusSp = () => {
    switch (code) {
      case CUSTOMER_STATUS_PURSUING:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            <Button
              className={`${classes.statusBtn} ${classes.statusBtn1}`}
              onClick={handleOpen4}
            >
              <DirectionsRunIcon className={classes.statusIcon} />
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                追客中
              </span>
            </Button>
          </Grid>
        );
      case CUSTOMER_STATUS_NOT_PURSUING:
        return (
          <Grid className={`${classes.status} ${classes.status2}`}>
            <Button
              className={`${classes.statusBtn} ${classes.statusBtn2}`}
              onClick={handleOpen4}
            >
              <BlockIcon className={classes.statusIcon} />
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                追わない
              </span>
              <Typography className={classes.statusTxt}>
                {NO_CONTACT_DETAIL_REASON_CODE[input.detail.nonContactReasonCode]}
              </Typography>
            </Button>
          </Grid>
        );
      case CUSTOMER_STATUS_CONTRACT:
        return (
          <Grid className={`${classes.status} ${classes.status4}`}>
            <Button
              className={`${classes.statusBtn} ${classes.statusBtn4}`}
              onClick={handleOpen4}
            >
              <Icon className={classes.statusIcon}>verified</Icon>
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                契約済み
              </span>
              <span className={classes.statusTxt}>
                大島420⑥-2
              </span>
            </Button>
            <Button
              variant="contained"
              className={`${baseClasses.buttonsPrimary} ${classes.button2}`}
              onClick={handleOpen14}
            >
              履歴
            </Button>
          </Grid>
        );
      case CUSTOMER_STATUS_CLAIM:
        return (
          <Grid className={`${classes.status} ${classes.status3}`}>
            <Grid
              className={`${classes.statusBtn} ${classes.statusBtn3}`}
            >
              <FlashOnIcon className={classes.statusIcon} />
              <span className={`${baseClasses.title3} ${classes.statusVal}`}>
                クレーム
              </span>
            </Grid>
            <Button
              variant="contained"
              className={classes.button1}
              onClick={handleOpen13}
            >
              解除申請
            </Button>
            <LiftWindow
              open={open13}
              onClose={handleClose13}
            />
          </Grid>
        );
      default:
        return (
          <Grid className={`${classes.status} ${classes.status1}`}>
            該当なし
          </Grid>
        );
    }
  };

  const getIcon = (val) => {
    switch (Number(val)) {
      case 1:
        return (
          <DirectionsRunIcon className={classes.statusIcon} />
        );
      case 2:
        return (
          <BlockIcon className={classes.statusIcon} />
        );
      case 10:
        return (
          <Icon className={classes.statusIcon}>verified</Icon>
        );
      case 11:
        return (
          <FlashOnIcon className={classes.statusIcon} />
        );
      default:
        return (
          <span>該当なし</span>
        );
    }
  };

  const getBox1 = () => {
    return (
      <Grid className={classes.inHouseInfoName}>
        <Typography
          className={`${classes.inHouseInfoNameText}`}
        >
          {input.detail.userLastName}　{input.detail.userFirstName}
        </Typography>
      </Grid>
    );
  };
  const getBox2 = () => {
    return (
      <Grid>
        {menus1.map((data) => {
          return (
            <Button
              className={`${baseClasses.title3} ${classes.selectBtn2} ${(Number(input.detail.customerStatusCode) === Number(data)) && 'select'}`}
              key={data}
              onClick={() => {
                onSelect1(data);
                handleClose4();
              }}
            >
              {getIcon(data)}
              {LEAVE_STATUS[data]}
            </Button>
          );
        })}
      </Grid>
    );
  };
  const getBox3 = () => {
    return (
      <Select
        defaultValue={input.detail.telStatusCode ? input.detail.telStatusCode : 99}
        value={input.detail.telStatusCode}
      >
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus2.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect2(data);
              }}
            >
              {TEL_STATUS_CODE[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox4 = () => {
    return (
      <Select
        defaultValue={input.detail.emailStatusCode ? input.detail.emailStatusCode : 99}
        value={input.detail.emailStatusCode}
      >
        <MenuItem disabled value={99}>未選択</MenuItem>
        {menus3.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect3(data);
              }}
            >
              {EMAIL_STATUS_CODE[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox5 = () => {
    return (
      <Select
        defaultValue={input.detail.noContactDetailReasonCode}
        disabled={code !== CUSTOMER_STATUS_NOT_PURSUING}
      >
        {menus4.map((data) => {
          return (
            <MenuItem
              key={data}
              value={data}
              onClick={() => {
                onSelect4(data);
              }}
            >
              {NO_CONTACT_DETAIL_REASON_CODE[data]}
            </MenuItem>
          );
        })}
      </Select>
    );
  };
  const getBox6 = () => {
    return (
      <TextBaseField
        label="購入できない理由"
        defaultValue={input.detail.reasonNotBuy}
        className={classes.textField}
        name="reasonNotBuy"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        errorCallback={input.errorCallback('reasonNotBuy')}
        disabled={code !== CUSTOMER_STATUS_NOT_PURSUING}
      />
    );
  };
  const getBox7 = () => {
    return (
      <Calendar
        data={{
          type: 'date',
          label: '検討再開時期',
          className: classes.calendar,
          state: input.detail.searchStartAt?.substr(0, 10),
          setState: dateChange,
        }}
      />
    );
  };
  const getBox8 = () => {
    return (
      <TextBaseField
        label="お客様が探していなかった理由"
        defaultValue={input.detail.reasonNotSearch}
        className={classes.textField}
        name="reasonNotSearch"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        errorCallback={input.errorCallback('reasonNotSearch')}
        disabled={code !== CUSTOMER_STATUS_NOT_PURSUING}
      />
    );
  };
  const getBox9 = () => {
    return (
      <TextBaseField
        label="要望エリア（当時のOHDエリア以外）"
        defaultValue={input.detail.searchArea}
        className={classes.textField}
        name="searchArea"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        errorCallback={input.errorCallback('searchArea')}
        disabled={code !== CUSTOMER_STATUS_NOT_PURSUING}
      />
    );
  };
  const getBox10 = () => {
    return (
      <TextBaseField
        label="他OHDにはまらない理由"
        defaultValue={input.detail.reasonNotOhd}
        className={classes.textField}
        name="reasonNotOhd"
        onBlur={onBlur}
        placeholder="-"
        convertor={convertForm1}
        errorCallback={input.errorCallback('reasonNotOhd')}
        disabled={code !== CUSTOMER_STATUS_NOT_PURSUING}
      />
    );
  };
  const getBox11 = () => {
    return (
      <FormControlLabel
        className={classes.formControlLabel}
        control={
          <Checkbox
            checked={checked}
            onChange={isCustomerIntroductionChange}
            name="isCustomerIntroduction"
          />
        }
        label="顧客紹介対象"
      />
    );
  };

  // 顧客ステータスで表示を分岐
  const body = (
    <Grid>
      <Grid className={classes.inHouseInfoBox}>
        <Typography className={baseClasses.title4}>
          「追わない」詳細情報
        </Typography>
      </Grid>
      <Grid className={classes.inHouseInfoBox}>
        <Grid className={classes.inHouseInfoBlock1}>
          <Typography className={baseClasses.title6}>
            理由
          </Typography>
          <Grid className={classes.inHouseInfoSelect}>
            {input.dataInput.component(customerSelectList.nonContactReasonCodeItem)}
          </Grid>
        </Grid>
        <Grid className={classes.inHouseInfoBlock1}>
          {getBox6()}
        </Grid>
        <Grid className={classes.inHouseInfoBlock1}>
          {getBox7()}
        </Grid>
        <Grid className={classes.inHouseInfoBlock1}>
          {getBox8()}
        </Grid>
      </Grid>
      <Grid className={classes.inHouseInfoBox}>
        <Grid className={classes.inHouseInfoBlock2}>
          {getBox9()}
        </Grid>
        <Grid className={classes.inHouseInfoBlock2}>
          {getBox10()}
        </Grid>
      </Grid>
    </Grid>
  );
  const getDetail = () => {
    switch (input.detail.customerStatusCode) {
      case 2:
        return (
          <Grid className={classes.inHouseInfoStatus1}>
            {body}
          </Grid>
        );
      default:
        return (
          <Grid className={classes.inHouseInfoStatus2}>
            {body}
          </Grid>
        );
    }
  };

  const getUserLevelSp = () => {
    return (
      <Grid>
        {rating.map((data) => {
          return (
            <Button
              className={`${baseClasses.title3} ${classes.selectBtn} ${(Number(input.detail.userLevel) === Number(data.no)) && 'select'}`}
              key={data.no}
              onClick={() => {
                onSelect5(data.no);
                handleClose15();
              }}
            >
              <Rating
                name="size-large"
                value={data.no}
                size="large"
                className={classes.starLbl}
              />
            </Button>
          );
        })}
      </Grid>
    );
  };

  const list1 = [
    {
      type1: 'button',
      type2: 'text',
      id: 1,
      lbl: '担当者（組織）',
      val: input.detail.userLastName + '　' + input.detail.userFirstName,
      click: handleOpen3,
      opn: open3,
      onClose: handleClose3,
      content: getBox1,
      decision: onDecision,
    },
  ];

  const list2 = [
    {
      type1: 'button',
      type2: 'select',
      lbl: '状態',
      click: handleOpen4,
      fontSize: '16px',
      opn: open4,
      onClose: handleClose4,
      content: getBox2,
    },
  ];

  const list3 = [
    {
      type1: 'noModal',
      type2: 'select',
      id: 3,
      lbl: '電話ステータス',
      val: TEL_STATUS_CODE[input.detail.telStatusCode],
      content: getBox3,
    },
    {
      type1: 'noModal',
      type2: 'select',
      id: 4,
      lbl: 'メール許可',
      val: EMAIL_STATUS_CODE[input.detail.emailStatusCode],
      content: getBox4,
    },
  ];

  const list4 = [
    {
      type1: 'noModal',
      type2: 'select',
      id: 5,
      lbl: '理由',
      val: NO_CONTACT_DETAIL_REASON_CODE[input.detail.nonContactReasonCode],
      content: getBox5,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 6,
      lbl: '購入できない理由',
      val: input.detail.reasonNotBuy,
      content: getBox6,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 7,
      lbl: '検討再開時期',
      val: input.detail.searchStartAt,
      content: getBox7,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 8,
      lbl: 'お客様が探していなかった理由',
      val: input.detail.reasonNotSearch,
      content: getBox8,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 9,
      lbl: '要望エリア（当時のOHDエリア以外）',
      val: input.detail.searchArea,
      content: getBox9,
    },
    {
      type1: 'noModal',
      type2: 'text',
      id: 10,
      lbl: '他OHDにはまらない理由',
      val: input.detail.reasonNotOhd,
      content: getBox10,
    },
  ];

  const list5 = [
    {
      type1: 'button',
      type2: 'select',
      lbl: 'ランク',
      click: handleOpen15,
      opn: open15,
      onClose: handleClose15,
      content: getUserLevelSp,
    },
  ];

  const list6 = [
    {
      type1: 'checkbox',
      type2: '',
      lbl: '顧客紹介対象',
      val: checked,
      content: getBox11,
    },
  ];

  if (isSp) {
    contents = (
      <Grid>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          社内情報
        </Typography>
        <List className={classes.list}>
          {list1.map((item) => {
            return (
              <ListItem
                key={item.id}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <Grid className={classes.statusWrap}>
          {getStatusSp()}
          {input.dataInput.editDialog(list2[0])}
        </Grid>
        <List className={classes.list}>
          {list3.map((item) => {
            return (
              <ListItem
                key={item.id}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <Typography className={`${baseClasses.title4} ${classes.ttl}`}>
          「追わない」詳細情報
        </Typography>
        <List className={`${classes.list} ${code !== CUSTOMER_STATUS_NOT_PURSUING && 'disabled'}`}>
          {list4.map((item) => {
            return (
              <ListItem
                key={item.id}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
        <List className={`${classes.list} ${classes.listRank}`}>
          <ListItem
            className={classes.listItem}
          >
            <Button
              className={classes.listBtn}
              onClick={handleOpen15}
            >
              <span className={`${baseClasses.title6} ${classes.listLbl}`}>
                ランク（課員）
              </span>
              <Rating
                name="size-large"
                value={star}
                size="large"
                className={classes.starLbl}
              />
            </Button>
            {input.dataInput.editDialog(list5[0])}
          </ListItem>
          {list6.map((item) => {
            return (
              <ListItem
                key={item.lbl}
                className={classes.listItem}
              >
                {input.dataInput.parts(item)}
              </ListItem>
            );
          })}
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid className={classes.root}>
        <Typography className={baseClasses.title4}>
          社内情報
        </Typography>
        <Grid className={classes.inHouse}>
          <Grid className={classes.inHouseInfo}>
            <Grid className={classes.inHouseInfoBox}>
              <Grid className={`${classes.inHouseInfoBlock1} ${(input.detail.customerStatusCode === 11) && classes.claim}`}>
                <Typography className={baseClasses.title6}>
                  担当者（組織）
                </Typography>
                {getBox1()}
              </Grid>
              <Grid className={`${classes.inHouseInfoBlock1} ${(input.detail.customerStatusCode === 11) && classes.claim}`}>
                <Typography className={baseClasses.title6}>
                  状態
                </Typography>
                <Grid className={classes.inHouseInfoSelect}>
                  <FormControl className={`${classes.formControl} ${classes.formControl2}`}>
                    <Select
                      value={code}
                      displayEmpty
                      className={classes.selectEmpty}
                      inputProps={{ 'aria-label': 'Without label' }}
                      onChange={customerStatusCodeChange}
                      onBlur={customerStatusCodeBlur}
                    >
                      {menusKeys.map((data) => (
                        <MenuItem
                          key={data}
                          value={data}
                        >
                          {LEAVE_STATUS[data]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid className={classes.inHouseInfoBlock1}>
                <Typography className={baseClasses.title6}>
                  電話ステータス
                </Typography>
                <Grid className={classes.inHouseInfoSelect}>
                  {input.dataInput.component(customerSelectList.telStatusCodeItem)}
                </Grid>
              </Grid>
              <Grid className={classes.inHouseInfoBlock1}>
                <Typography className={baseClasses.title6}>
                  メール許可
                </Typography>
                <Grid className={classes.inHouseInfoSelect}>
                  {input.dataInput.component(customerSelectList.emailStatusCodeItem)}
                </Grid>
              </Grid>
            </Grid>
            {getDetail()}
          </Grid>
        </Grid>
        <Grid className={classes.inHouseRankCheck}>
          <Grid className={classes.inHouseRank}>
            <Typography className={baseClasses.title6}>ランク（課員）</Typography>
            <Grid className={classes.star}>
              <Rating
                name="size-large"
                value={star}
                size="large"
                className={classes.starLbl}
              />
              <Grid className={classes.starSelect}>
                {input.dataInput.component(customerSelectList.rankItem)}
              </Grid>
            </Grid>
          </Grid>
          <Grid>
            {getBox11()}
          </Grid>
        </Grid>
        {/* 上長メモ利用 */}
        {roleIds.includes(LEADERS_MEMO_USE) && (
          <Grid className={classes.inHouse}>
            <Grid className={classes.inHouseTf}>
              <Typography className={`${classes.inHouseTfLbl} ${baseClasses.title6}`}>
                上長メモ
              </Typography>
              <TextBaseField
                className={`${classes.inHouseTfInput} ${classes.textField}`}
                defaultValue={input.detail.leaderMemo}
                name="leaderMemo"
                onBlur={onBlur}
                errorCallback={input.errorCallback('leaderMemo')}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <ClaimRegister
        open={open2}
        onClose={handleClose2}
        id=""
        setStatus={customerStatusSet11}
        customerId={input.detail.customerId}
        responsibleUserId={input.detail.userId}
      />
      <DoNotChaseReason
        open={open1}
        onClose={handleClose1}
        setStatus={customerStatusSet2}
        userData={userData}
        setUserData={setUserData}
      />
      <ContractInformation
        open={open14}
        onClose={handleClose14}
        customerId={input.detail.customerId}
      />
    </Grid>
  );
}
