import { useMemo, useState } from 'react';
import {
  makeStyles,
  TableCell,
  TableRow,
} from '@material-ui/core';
import {
  EditOutlined as EditOutlinedIcon,
  DeleteOutline as DeleteOutlineIcon,
} from '@material-ui/icons';
import FloatButtonGroup from './floatButtonGroup';
import ConfirmModal from '../../common/modal/confirmModal';
import EditDialog2 from '../editDialog/editDialog2';
import NormalTooltip from '../../styles/tooltip';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    '&.expired': {
      background: 'rgba(216, 52, 32, 0.08)!important',
    },
    '&.Mui-selected': {
      background: `${theme.palette.primaryColor}29!important`,
    },
    '&:hover': {
      background: `${theme.palette.primaryColor}29!important`,
      '& .floatButtonGroupHook': {
        opacity: 1,
      },
    },
    '&:nth-child(even)': {
      background: '#F3F3F3',
    },
  },
  tableTd: {
    position: 'relative',
    fontFamily: 'Roboto',
    fontSize: '13px',
    lineHeight: '1.6',
    letterSpacing: '0',
    padding: '4px',
  },
  txt: {
    overflow: 'hidden',
    width: '200px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  wd: {
    width: '50px',
  },
}));

export default function Row(props) {
  const {
    row,
    onDelete,
  } = props;

  const classes = useStyles();

  const [editOpen, setEditOpen] = useState(false);
  const [delOpen, setDelOpen] = useState(false);

  const buttonItems = useMemo(() => {
    const arr = [
      {
        label: '編集',
        icon: <EditOutlinedIcon />,
        onClick: () => setEditOpen(true),
      },
      {
        label: '削除',
        icon: <DeleteOutlineIcon />,
        onClick: () => setDelOpen(true),
      },
    ];
    return arr;
  }, [row]);

  return (
    <>
      <TableRow
        hover
        tabIndex={-1}
        className={classes.root}
      >
        <TableCell>
          {row.displayOrder}
        </TableCell>
        <TableCell>
          <NormalTooltip title={row.combinationName}>
            <div className={classes.txt}>
              {row.combinationName}
            </div>
          </NormalTooltip>
        </TableCell>
        <TableCell>
          <NormalTooltip title={row.targetCityName}>
            <div className={classes.txt}>
              {row.targetCityName}
            </div>
          </NormalTooltip>
        </TableCell>
        <TableCell>
          <NormalTooltip title={row.storeGroupNames3}>
            <div className={classes.txt}>
              {row.storeGroupNames3}
            </div>
          </NormalTooltip>
        </TableCell>
        <TableCell align="right" className={classes.wd}>
          {row.price1}
        </TableCell>
        <TableCell>
          <NormalTooltip title={row.storeGroupNames1}>
            <div className={classes.txt}>
              {row.storeGroupNames1}
            </div>
          </NormalTooltip>
        </TableCell>
        <TableCell align="right" className={classes.wd}>
          {row.price2}
        </TableCell>
        <TableCell>
          <NormalTooltip title={row.storeGroupNames2}>
            <div className={classes.txt}>
              {row.storeGroupNames2}
            </div>
          </NormalTooltip>
          <FloatButtonGroup
            items={buttonItems}
          />
        </TableCell>
      </TableRow>
      <ConfirmModal
        title="削除確認"
        open={delOpen}
        onCancel={() => setDelOpen(false)}
        content="該当の情報を削除しますか？"
        onSubmit={() => {
          onDelete();
          setDelOpen(false);
        }}
      />
      <EditDialog2
        row={row}
        open={editOpen}
        setOpen={setEditOpen}
      />
    </>
  );
}
