import React, { useState } from 'react';
import {
  FormGroup,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import commonTheme from '../../../styles/theme';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    display: 'inline-flex',
    alignItems: 'center',
    marginRight: '15px',
    [commonTheme.breakpoints.up('md')]: {
      marginBottom: '8px',
    },
    [commonTheme.breakpoints.down('sm')]: {
      padding: '16px 0 16px 16px',
      marginLeft: 0,
      justifyContent: 'space-between',
      width: '100%',
      borderBottom: '1px solid #f3f3f3',
    },
    '& > .MuiFormControlLabel-label': {
      fontSize: '13px',
      [commonTheme.breakpoints.down('sm')]: {
        paddingLeft: 4,
        fontWeight: 'bold',
        '& > span': {
          '&.active': {
            color: theme.palette.primaryColor,
          },
        },
      },
    },
  },
  checkboxParts: {
    [commonTheme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  title: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  wrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    '&[data-before]::before': {
      content: 'attr(data-before)',
      display: 'inline-block',
      marginRight: '16px',
      fontFamily: 'Roboto',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: '700',
    },
    '&[data-after]:not([data-after=""])': {
      paddingTop: '30px',
      [commonTheme.breakpoints.down('sm')]: {
        paddingTop: '38px',
      },
      '&::after': {
        content: 'attr(data-after)',
        display: 'inline-block',
        position: 'absolute',
        top: '12px',
        left: 0,
        fontFamily: 'Roboto',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '700',
      },
    },
    '&.disabled': {
      [commonTheme.breakpoints.up('md')]: {
        opacity: 0.3,
        pointerEvents: 'none',
      },
    },
  },
}));
function SearchPartsCheckbox(props) {
  const {
    flg,
    values,
  } = props;
  const classes = useStyles();
  const menusKeys = values?.constantsOrder || Object.keys(values.constants.menus);
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = windowWidth < breakPoint;

  const checkInitial = () => {
    switch (flg) {
      case '1':
        return true;
      default:
        return false;
    }
  };

  const [checked, setChecked] = useState(checkInitial());

  const handleChange = (e, valId) => {
    setChecked(e.target.checked);

    const copyState = Object.assign([], values.state);
    if (e.target.checked) {
      values.strValue ? copyState.push(e.target.value) : copyState.push(valId);
      values.setState(copyState);
    } else {
      const targetId = values.strValue ? e.target.value : valId;
      const i = copyState.indexOf(targetId);
      copyState.splice(i, 1);
      values.setState(copyState);
    }

    console.log(checked);
  };

  return (
    <FormGroup
      row
      className={`${classes.wrap} ${values.disabled && 'disabled'}`}
      data-before={values.before}
      data-after={!isSP ? values.after : ''}
    >
      {menusKeys.map((c) => (
        <FormControlLabel
          key={`checkbox${c}`}
          className={classes.checkbox}
          control={
            <Checkbox
              checked={
                values.strValue
                  ? values.state.includes(values.constants.menus[c])
                  : values.state.map(i => Number(i)).includes(Number(c))
              }
              onChange={(e) => {
                handleChange(e, Number(c));
              }}
              name={values.label}
              color="primary"
              value={values.constants.menus[c]}
              className={classes.checkboxParts}
            />
          }
          label={
            <span className={values.state.includes(c) ? 'active' : ''}>
              {values.constants.menus[Number(c)]}
            </span>
          }
        />
      ))}
    </FormGroup>
  );
}

export default SearchPartsCheckbox;
