import { useState } from 'react';
import {
  Button,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import commonStyles from '../styles';
import ListTable from './parts/listTable';
import { useCombinationSettingContext } from '../../containers/combinationSetting';
import EditDialog2 from './editDialog/editDialog2';
import AreaSelect from './parts/areaSelect';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    position: 'relative',
  },
  stickey: {
    position: 'sticky',
    top: 0,
    background: '#fff',
    zIndex: 1,
  },
  root: {
    maxWidth: '96%',
    margin: '0 auto',
    paddingBottom: 20,
  },
  conditionWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    flexWrap: 'wrap',
    padding: '20px',
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    '& button:last-child': {
      marginLeft: 20,
    },
  },
  table: {
    margin: '16px 20px',
  },
  listTitle: {
    margin: '10px',
  },
  startIcon: {
    color: theme.palette.primaryColor,
    width: 20,
    height: 20,
    marginRight: 0,
  },
  addButton: {
    '& span': {
      marginRight: 0,
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      '&>span': {
        color: '#DEDEDE',
      },
    },
  },
  addButtonTxt: {
    marginTop: '5px',
    color: theme.palette.primaryColor,
  },
  disabled: {
    color: '#DEDEDE',
  },
}));

export default function CombinationSetting() {
  const common = commonStyles();
  const classes = useStyles();

  const {
    // select state
    currentArea,
  } = useCombinationSettingContext();

  const [open, setOpen] = useState(false);

  return (
    <Grid className={classes.container}>
      <Grid className={`${common.header} ${classes.stickey}`}>
        <Grid className={classes.headerInnter}>
          <Typography className={common.title2}>
            組合せ設定
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.conditionWrap}>
        <AreaSelect />
      </Grid>
      <Grid className={classes.headerInnter} container>
        <Typography className={`${common.title2} ${classes.listTitle}`}>
          組合せ設定一覧
        </Typography>
        <Button
          className={classes.addButton}
          startIcon={<AddIcon className={`${classes.startIcon} ${!currentArea.areaCode ? `${classes.disabled}` : ''}`} />}
          disabled={!currentArea.areaCode}
          onClick={() => setOpen(true)}
        >
          <Typography
            className={`${common.strong} ${classes.addButtonTxt} ${!currentArea.areaCode ? `${classes.disabled}` : ''}`}
          >
            追加
          </Typography>
        </Button>
      </Grid>
      <ListTable className={classes.table} />
      <EditDialog2
        open={open}
        setOpen={setOpen}
        isNew
      />
    </Grid>
  );
}
