import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableContainer,
  TableBody,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Typography,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import EditStoreTableRow from './editStoreTableRow';
import commonStyles from '../../styles';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: '#fff',
    padding: 8,
    boxSizing: 'border-box',
  },
  table: {
    width: '100%',
  },
  cell: {
    padding: '4px 0px',
    whiteSpace: 'break-spaces',
    '& > span': {
      width: '100%',
    },
    '&:not(:last-child)': {
      '& > span': {
        borderRight: 'solid 1px #C8C8C8',
      },
    },
  },
  header: {
    borderBottom: 'solid 2px #C8C8C8',
  },
  headerTxt: {
    color: '#8C8C8C',
    paddingLeft: 5,
  },
  icon: {
    '& .MuiTableSortLabel-icon': { fontSize: '12px' },
    '&.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      color: theme.palette.primaryColor,
    },
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === a[orderBy]) return 0;
  return b[orderBy] < a[orderBy] ? -1 : 1;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead(props) {
  const { cells, order, orderBy, onRequestSort } = props;
  const common = commonStyles();
  const classes = useStyles();
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead className={classes.header}>
      <TableRow className={classes.tableRow}>
        {cells.map(c => (
          <TableCell
            key={c.id}
            style={c.style}
            className={classes.cell}
            sortDirection={orderBy === c.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === c.id}
              direction={orderBy === c.id ? order : 'asc'}
              onClick={createSortHandler(c.id)}
              className={classes.icon}
            >
              <Typography
                className={`${common.title4} ${classes.headerTxt}`}
              >
                {c.label}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
};

function EditStoreTable(props) {
  const {
    mode,
    registeredStores = [],
    deleteStore,
  } = props;
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('responseRegistrationOrder');
  const classes = useStyles();

  const allHeader = [
    { id: 'responseRegistrationOrder', label: 'No', style: { width: '10px' } },
    { id: 'divisionName', label: '店舗名', style: { width: '120px' } },
    { id: 'isNextRegistration', label: '次の登録先店舗', style: { width: '100px', textAlign: 'center' } },
    { id: 'trash', label: '', style: { width: '20px' } },
  ];

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <EnhancedTableHead
          cells={allHeader}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
        />
        <TableBody>
          {stableSort(registeredStores, getComparator(order, orderBy))
            .map((row) => {
              return (
                <EditStoreTableRow
                  row={row}
                  key={`${row.divisionName}${row.responseRegistrationOrder}`}
                  registeredStores={registeredStores}
                  deleteStore={deleteStore}
                  mode={mode}
                />
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default EditStoreTable;
