import {
  Grid,
  makeStyles,
} from '@material-ui/core';
import commonStyles from '../../styles';
import Select from '../../division/select';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: 20,
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'nowrap',
      whiteSpace: 'nowrap',
    },
    '&:nth-child(2)': {
      marginTop: 10,
      justifyContent: 'flex-end',
    },
  },
});

export default function AreaSelect() {
  const {
    // select options
    areaOptions,
    // select state
    currentArea,
    setCurrentArea,
  } = useCombinationSettingContext();

  const common = commonStyles();
  const classes = useStyles();

  return (
    <Grid className={classes.root}>
      <Grid className={common.selectBox}>
        <Select
          row
          label="エリア"
          value={currentArea.areaCode ?? 'undefined'}
          options={areaOptions}
          labelKey="areaName"
          valueKey="areaCode"
          onChange={(val, item) => {
            setCurrentArea(item);
          }}
          placeholder="未選択"
        />
      </Grid>
    </Grid>
  );
}
