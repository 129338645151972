/* eslint-disable max-len */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Grid, Button, makeStyles, Select, MenuItem, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Modal from '../../common/modal';
import commonStyles from '../../styles';
import { LabelWrapGroup, LabelWrap } from '../../common/labelWrap';
import { classNames } from '../../../commonFunction';
import StoreGroupsDialog from '../storeGroupsDialog';
import inputNumber from '../../../commonFunction/inputNumber';
import { COMBINATION_SETTING } from '../../../constants/loading';
import { useLoading } from '../../../hooks';
import {
  validateFormString1,
  validateFormInt1,
  validateFormNumLarge,
  validateFormNumSmall,
} from '../../../commonFunction/validations';
import { convertFormNumber } from '../../../commonFunction/convertors';
import { TextBaseField } from '../../eleCommon/validation';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';
import AreaSentaku from '../../common/areaSentaku';
import { ABOVE_BUDGET_AMOUNT, BELOW_BUDGET_AMOUNT, NO_SETTING, SETTING_TYPE } from '../../../constants/combinationSetting';

const useStyle = makeStyles((theme) => ({
  topWrap: {
    display: 'flex',
    '& > div': {
      flex: 1,
    },
  },
  footerButtonWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
  },
  offsetTop: {
    marginTop: '.5rem !important',
  },
  offsetLeft: {
    marginLeft: '1rem',
  },
  offsetRight: {
    marginRight: '1rem',
  },
  offsetBoth: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  wrap: {
    background: '#fff',
    padding: 8,
    border: '1px #ccc solid',
  },
  wrap2: {
    padding: '8px 162px 8px 8px',
  },
  width1: {
    width: '100%',
  },
  width2: {
    width: '80%',
  },
  width3: {
    width: '58%',
  },
  width4: {
    width: '20%',
    marginLeft: '7px',
  },
  label: {
    '& span.required': {
      color: '#D83420',
    },
  },
  content: {
    marginLeft: '10px',
    fontSize: '13px',
    lineHeight: 1.2,
    letterSpacing: 0,
    textAlign: 'left',
  },
  startIcon: {
    color: theme.palette.primaryColor,
    width: 16,
    height: 16,
    marginRight: 0,
  },
  addButton: {
    '& span': {
      marginRight: 0,
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      '&>span': {
        color: '#DEDEDE',
      },
    },
  },
  addButtonTxt: {
    color: theme.palette.primaryColor,
  },
}));

export default function EditDialog2(props) {
  const {
    currentArea,
    registerApi,
    getDetailApi,
    updateApi,
  } = useCombinationSettingContext();

  const { row, open, setOpen, isNew = false } = props;

  const { hasLoading } = useLoading();

  const baseClasses = commonStyles();
  const classes = useStyle();

  const [openArea, setOpenArea] = useState(false);
  const [openStore, setOpenStore] = useState(false);
  const [mode, setMode] = useState(NO_SETTING); // 振り分け条件の判断用

  const [modalOpen, setModalOpen] = useState(false);

  // 各項目管理用state
  const [combinationName, setCombinationName] = useState('');
  const [displayOrder, setDisplayOrder] = useState(999);
  const [settingType, setSettingType] = useState(1);
  const [targetCityCode, setTargetCityCode] = useState('');
  const [price1, setPrice1] = useState(null);
  const [price2, setPrice2] = useState(null);
  const [divisions1, setDivisions1] = useState([]);
  const [divisions2, setDivisions2] = useState([]);
  const [divisions3, setDivisions3] = useState([]);

  const combinationTypeId1 = useRef(null);
  const combinationTypeId2 = useRef(null);
  const combinationTypeId3 = useRef(null);

  const [initialParamArea, setInitialParamArea] = useState({
    wishAreaCode: currentArea?.areaCode ?? 30,
    wishArea: '',
    wishAreaIds: [],
  });

  const [errorSet, setErrorSet] = useState(new Set());

  const errorCallback = (key) => (hasError) => {
    setErrorSet((prev) => {
      if (hasError) return new Set(prev.add(key));
      prev.delete(key);
      return new Set(prev);
    });
  };

  // ボタン制御
  const [disable, setDisable] = useState(false);
  useEffect(() => {
    if (
      !combinationName?.length
      || !displayOrder
      || !(targetCityCode?.length && targetCityCode.length <= 100)
      || ((Number(price1) && !divisions1?.length) || (!Number(price1) && divisions1?.length))
      || ((Number(price2) && !divisions2?.length) || (!Number(price2) && divisions2?.length))
      || !divisions3?.length
    ) return setDisable(true);
    return setDisable(false);
  }, [
    combinationName,
    displayOrder,
    targetCityCode,
    price1,
    price2,
    divisions1,
    divisions2,
    divisions3,
  ]);

  const onClose = () => {
    setOpen(false);
    setModalOpen(false);
  };

  const initialize = () => {
    setCombinationName('');
    setDisplayOrder(999);
    setSettingType(1);
    setTargetCityCode('');
    setPrice1(null);
    setPrice2(null);
    setDivisions1([]);
    setDivisions2([]);
    setDivisions3([]);
    setInitialParamArea({
      wishAreaCode: currentArea?.areaCode ?? 30,
      wishArea: '',
      wishAreaIds: [],
    });
    combinationTypeId1.current = null;
    combinationTypeId2.current = null;
    combinationTypeId3.current = null;
  };

  const setInitial = (d) => {
    setCombinationName(d.combinationName);
    setDisplayOrder(d.displayOrder);
    setSettingType(d.settingType);
    setTargetCityCode(d.targetCityCode);
    setPrice1(d.combinations?.find(c => c.combinationType === ABOVE_BUDGET_AMOUNT)?.price ?? null);
    setPrice2(d.combinations?.find(c => c.combinationType === BELOW_BUDGET_AMOUNT)?.price ?? null);
    setDivisions1(d.combinations?.find(c => c.combinationType === ABOVE_BUDGET_AMOUNT)?.divisions ?? []);
    setDivisions2(d.combinations?.find(c => c.combinationType === BELOW_BUDGET_AMOUNT)?.divisions ?? []);
    setDivisions3(d.combinations?.find(c => c.combinationType === NO_SETTING)?.divisions ?? []);
    setInitialParamArea({
      wishAreaCode: d.areaCode,
      wishArea: d.targetCityName,
      wishAreaIds: d.targetCityCode.split('/').filter(Boolean),
    });
    combinationTypeId1.current = d.combinations?.find(c => c.combinationType === ABOVE_BUDGET_AMOUNT)?.combinationTypeId ?? null;
    combinationTypeId2.current = d.combinations?.find(c => c.combinationType === BELOW_BUDGET_AMOUNT)?.combinationTypeId ?? null;
    combinationTypeId3.current = d.combinations?.find(c => c.combinationType === NO_SETTING)?.combinationTypeId ?? null;
  };

  const onSubmit = useCallback(async () => {
    const tmpDetails = [];
    if (divisions1.length) {
      const tmp = {
        combinationTypeId: combinationTypeId1.current,
        combinationType: ABOVE_BUDGET_AMOUNT,
        price: price1,
        divisions: divisions1,
      };
      if (isNew || tmp.combinationTypeId === null) delete tmp.combinationTypeId;
      tmpDetails.push(tmp);
    }
    if (divisions2.length) {
      const tmp = {
        combinationTypeId: combinationTypeId2.current,
        combinationType: BELOW_BUDGET_AMOUNT,
        price: price2,
        divisions: divisions2,
      };
      if (isNew || tmp.combinationTypeId === null) delete tmp.combinationTypeId;
      tmpDetails.push(tmp);
    }
    if (divisions3.length) {
      const tmp = {
        combinationTypeId: combinationTypeId3.current,
        combinationType: NO_SETTING,
        divisions: divisions3,
      };
      if (isNew || tmp.combinationTypeId === null) delete tmp.combinationTypeId;
      tmpDetails.push(tmp);
    }
    const params = {
      areaCode: currentArea.areaCode,
      combinationName,
      targetCityCode,
      displayOrder,
      settingType,
      details: tmpDetails,
    };
    if (!isNew) params.combinationId = row.combinationId;
    onClose();
    isNew ? await registerApi(params, initialize) : await updateApi(params, initialize);
  }, [
    combinationName,
    displayOrder,
    settingType,
    targetCityCode,
    price1,
    price2,
    divisions1,
    divisions2,
    divisions3,
  ]);

  const onSelectedStore = useCallback((data) => {
    switch (mode) {
      case ABOVE_BUDGET_AMOUNT:
        setDivisions1(data);
        break;
      case BELOW_BUDGET_AMOUNT:
        setDivisions2(data);
        break;
      case NO_SETTING:
        setDivisions3(data);
        break;
      default:
    }
  }, [mode]);

  useEffect(() => {
    inputNumber();
  }, []);

  useEffect(async () => {
    if (open) {
      if (!isNew) {
        const data = await getDetailApi(row.combinationId);
        setInitial(data);
      }
      setModalOpen(true);
    }
  }, [open]);

  // エリア切り替え時に入力情報を初期化
  useEffect(() => {
    initialize();
  }, [currentArea?.areaCode]);

  return modalOpen && (
    <Modal
      open={modalOpen}
      onClose={onClose}
      title="組合せ設定ウィンドウ"
      maxWidth={800}
      buttons={[{
        className: baseClasses.buttonsPrimary,
        label: '登録',
        onClick: onSubmit,
        disabled: disable || errorSet.size !== 0,
      }]}
      className={`${hasLoading(COMBINATION_SETTING) ? baseClasses.loading : ''}`}
    >
      <Grid container>
        <Grid item className={baseClasses.title4}>
          エリア
        </Grid>
        <Grid item className={classes.content}>
          {currentArea?.areaName}
        </Grid>
      </Grid>
      <Grid container>
        <LabelWrap label="組合せ名" className={`${classes.offsetTop} ${classes.wrap} ${classes.width3}`} required>
          <TextBaseField
            defaultValue={combinationName ?? ''}
            required
            name="combinationName"
            onChange={(e) => setCombinationName(e.target.value)}
            inline
            gap={10}
            validator={validateFormString1({ maxLengthInt: 20 })}
            errorCallback={errorCallback('combinationName')}
          />
        </LabelWrap>
        <LabelWrap label="表示順序" className={`${classes.offsetTop} ${classes.wrap} ${classes.width4}`} required>
          <TextBaseField
            className={classes.content}
            defaultValue={displayOrder ?? 999}
            required
            type="number"
            name="displayOrder"
            onChange={(e) => setDisplayOrder(e.target.value)}
            inline
            gap={10}
            inputProps={{ min: '0' }}
            validator={validateFormInt1({ minFloat: 1.0, maxFloat: 999.0 })}
            convertor={convertFormNumber}
            errorCallback={errorCallback('displayOrder')}
          />
        </LabelWrap>
        <LabelWrap label="設定" className={`${classes.offsetTop} ${classes.wrap} ${classes.width4}`} required>
          <Select
            id="settingType"
            value={settingType}
            options={SETTING_TYPE}
            onChange={(e) => {
              setSettingType(e.target.value);
            }}
          >
            {Object.keys(SETTING_TYPE).reverse().map((idx) => {
              return (
                <MenuItem
                  key={idx}
                  value={idx}
                >
                  {SETTING_TYPE[idx]}
                </MenuItem>
              );
            })}
          </Select>
        </LabelWrap>
      </Grid>
      <LabelWrapGroup labelWidth="120px">
        <LabelWrap label="希望地域（最大16地域）" className={`${classes.offsetTop} ${classes.wrap}`} labelWidth="140px" required>
          <Button
            className={classes.content}
            onClick={() => setOpenArea(true)}
          >
            {initialParamArea.wishArea || '未入力'}
          </Button>
        </LabelWrap>
        予算振分条件、振分先（優先順位：万円以上＞万円以下＞設定なし）
        <Grid container>
          <LabelWrap label="万円以上" className={`${classes.offsetTop} ${classes.wrap}`} labelWidth="60px">
            <TextBaseField
              defaultValue={price1 ?? ''}
              name="price1"
              onChange={(e) => setPrice1(e.target.value)}
              inline
              gap={10}
              inputProps={{ min: '0' }}
              validator={validateFormNumLarge(Number(price2))}
              convertor={convertFormNumber}
              errorCallback={errorCallback('price1')}
            />
          </LabelWrap>
          <Button
            className={classNames(classes.addButton, classes.offsetBoth)}
            startIcon={<AddIcon className={classes.startIcon} />}
            onClick={() => {
              setMode(ABOVE_BUDGET_AMOUNT);
              setOpenStore(true);
            }}
          >
            <Typography
              className={`${baseClasses.smallStrong} ${classes.addButtonTxt}`}
            >
              店舗設定
            </Typography>
          </Button>
        </Grid>
        <Grid container>
          <LabelWrap label="万円以下" className={`${classes.offsetTop} ${classes.wrap}`} labelWidth="60px">
            <TextBaseField
              defaultValue={price2 ?? ''}
              name="price2"
              onChange={(e) => setPrice2(e.target.value)}
              inline
              gap={10}
              inputProps={{ min: '0' }}
              validator={validateFormNumSmall(Number(price1))}
              convertor={convertFormNumber}
              errorCallback={errorCallback('price2')}
            />
          </LabelWrap>
          <Button
            className={classNames(classes.addButton, classes.offsetBoth)}
            startIcon={<AddIcon className={classes.startIcon} />}
            onClick={() => {
              setMode(BELOW_BUDGET_AMOUNT);
              setOpenStore(true);
            }}
          >
            <Typography
              className={`${baseClasses.smallStrong} ${classes.addButtonTxt}`}
            >
              店舗設定
            </Typography>
          </Button>
        </Grid>
        <Grid container>
          <LabelWrap label="設定なし" className={`${classes.offsetTop} ${classes.wrap2}`} labelWidth="60px" required />
          <Button
            className={classNames(classes.addButton, classes.offsetBoth)}
            startIcon={<AddIcon className={classes.startIcon} />}
            onClick={() => {
              setMode(NO_SETTING);
              setOpenStore(true);
            }}
          >
            <Typography
              className={`${baseClasses.smallStrong} ${classes.addButtonTxt}`}
            >
              店舗設定
            </Typography>
          </Button>
        </Grid>
      </LabelWrapGroup>
      <StoreGroupsDialog
        open={openStore}
        mode={mode}
        onClose={() => setOpenStore(false)}
        onSubmit={onSelectedStore}
        divisions1={divisions1}
        divisions2={divisions2}
        divisions3={divisions3}
        areaCode={currentArea.areaCode}
      />
      <AreaSentaku
        initialParam={initialParamArea}
        open={openArea}
        onClose={() => setOpenArea(false)}
        onUpdate={(e) => {
          setInitialParamArea(e);
          if (e.wishAreaIds?.length) {
            const cp = structuredClone(e.wishAreaIds);
            cp.sort();
            const t = '/' + cp.join('/') + '/';
            setTargetCityCode(t);
          } else {
            setTargetCityCode('');
          }
        }}
        isCombinationSetting
      />
    </Modal>
  );
}
