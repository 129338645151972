import { useState } from 'react';
import {
  TableCell,
  makeStyles,
  InputAdornment,
  Button,
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import TextBaseField from './../../eleCommon/validation/TextBaseField';
import { validateFormString1 } from '../../../commonFunction/validations';

const useStyles = makeStyles({
  tableSearchRowCell: {
    margin: '0px',
    padding: '4px',
  },
  iconAdornment: {
    padding: '0px',
  },
  searchIcon: {
    height: '1rem',
  },
  textArea: {
    width: '100%',
    margin: '0px',
    backgroundColor: '#FFFFFF',
    border: '0px solid #FFFFFF',
  },
  textInput: {
    padding: '5px',
    fontSize: '13px',
    '&.MuiSelect-select:focus': { background: 'none' },
  },
  searchButton: {
    padding: '6px 0',
    minWidth: 'auto',
  },
});

function SearchCell(props) {
  const { initial, style, setSearchParam } = props;
  const classes = useStyles();

  const [searchValue, setSearchValue] = useState('');

  const onChange = (e) => {
    setSearchValue(e.target.value);
  };

  const filterFunc = (val) => {
    setSearchParam((state) => ({ ...state, [initial.key]: val }));
  };

  const onClickSearch = () => {
    filterFunc(searchValue);
  };

  const onKeyDown = (e) => {
    if (e.key !== 'Enter') return;
    filterFunc(e.target.value);
  };

  switch (initial.type) {
    default:
      return (
        <TableCell style={style} className={classes.tableSearchRowCell}>
          <TextBaseField
            className={classes.textArea}
            onChange={onChange}
            onKeyDown={onKeyDown}
            margin="none"
            InputProps={{
              classes: {
                input: classes.textInput,
                adornedStart: classes.iconAdornment,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <Button
                    onClick={onClickSearch}
                    className={classes.searchButton}
                  >
                    <SearchIcon className={classes.searchIcon} />
                  </Button>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            size="small"
            validator={validateFormString1({
              maxLengthInt: 50,
            })}
          />
        </TableCell>
      );
  }
}

export default SearchCell;
