import {
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableSortLabel,
} from '@material-ui/core';

import SearchCell from './searchCell';
import { COMBINATION_HEADER_ID, COMBINATION_HEADER_TH } from '../../../constants/combinationSetting';

const useStyles = makeStyles({
  tableTop: {
    paddingTop: '100px',
  },
  tableTopCell: {
    border: '0px none',
    padding: '10px',
  },
  tableHead: {
    padding: '4px 0px',
    borderBottom: '2px solid',
    color: '#F3F3F3',
  },
  tableHeadCell: {
    margin: '0px',
    padding: '0px',
    color: '#8C8C8C',
  },
  pagenation: {
    '& .MuiTablePagination-input': { display: 'none' },
    '& .MuiTablePagination-caption:first-of-type': { display: 'none' },
  },
});

function TableHeaders(props) {
  const {
    setSearchParam,
    order,
    orderBy,
    onRequestSort,
  } = props;

  const classes = useStyles();

  const searchObjs = [
    {
      key: 'combinationName',
      type: 'text',
    },
    {
      key: 'targetCityName',
      type: 'text',
    },
    {
      key: 'storeGroupNames3',
      type: 'text',
    },
    {
      key: 'price1',
      type: 'text',
    },
    {
      key: 'storeGroupNames1',
      type: 'text',
    },
    {
      key: 'price2',
      type: 'text',
    },
    {
      key: 'storeGroupNames2',
      type: 'text',
    },
  ];

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow className={classes.tableHead}>
        {COMBINATION_HEADER_TH.map((th, index) => (
          <TableCell
            style={{ top: 50.75 }}
            className={classes.tableHeadCell}
            key={th}
          >
            <TableSortLabel
              active={orderBy === COMBINATION_HEADER_ID[index]}
              direction={orderBy === COMBINATION_HEADER_ID[index] ? order : 'asc'}
              onClick={createSortHandler(COMBINATION_HEADER_ID[index])}
              className={classes.icon}
            >
              {th}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
      <TableRow className={classes.tableSearchRow}>
        <TableCell style={{ top: 75.75 }} />
        {searchObjs.map((obj) => (
          <SearchCell
            style={{ top: 75.75 }}
            key={obj.key}
            initial={obj}
            setSearchParam={setSearchParam}
          />
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeaders;
