import React from 'react';
import { useDispatch } from 'react-redux';
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Radio,
} from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { makeStyles } from '@material-ui/core/styles';
import commonStyles from '../../styles';
import { changeConfirmMessage } from '../../../store/eleCommon/customConfirmMessage';
import { NO_SETTING } from '../../../constants/combinationSetting';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    '&:nth-of-type(even)': {
      backgroundColor: '#F3F3F3',
    },
    '& td': {
      padding: '4px 0',
    },
  },
  tableCellTxt: {
    borderLeft: 'solid 1px #C8C8C8',
    paddingLeft: 5,
    minHeight: 24,
    '& input[type="radio"]': {
      backgroundColor: theme.palette.primaryColor,
    },
    '& .MuiRadio-colorSecondary.Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& span': {
      padding: 0,
      marginRight: 0,
      '& svg': {
        width: 16,
        height: 16,
      },
    },
  },
  deleteOutlineIcon: {
    color: '#D83420',
  },
  dialog: {
    dialog: {
      '& .MuiDialog-paperWidthSm': {
        maxWidth: 'none',
      },
    },
  },
  dialogContainer: {
    width: 515,
    padding: 24,
  },
  DialogTitle: {
    padding: '24px 24px 8px 24px',
    textAlign: 'center',
  },
  DialogContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    gap: 50,
    '& .MuiButton-text': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '15px',
      lineHeight: '160%',
      color: theme.palette.primaryColor,
    },
  },
  cancelTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  cancelParagraph: {
    textAlign: 'center',
    marginBottom: 50,
  },
  choiceButton: {
    minWidth: 'auto',
    fontSize: 13,
    borderLeft: 'solid 1px #C8C8C8',
    padding: '2px 4px',
    color: '#0679BF',
    textAlign: 'center',
  },
  deleteButton: {
    minWidth: 'auto',
    padding: '4px',
    textAlign: 'center',
    '&.Mui-disabled svg': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
}));

function EditStoreTableRow(props) {
  const {
    row,
    registeredStores,
    mode,
    deleteStore,
  } = props;
  const classes = useStyles();
  const baseClasses = commonStyles();

  const dispatch = useDispatch();

  const confirmOpen = () => {
    dispatch(
      changeConfirmMessage({
        title: '店舗の削除確認',
        msgList: ['選択した店舗を削除してもよろしいですか？'],
        buttons: [
          {
            label: 'CANCEL',
            set: () => {},
            classes: baseClasses.buttonsSecondary,
          },
          {
            label: 'OK',
            set: () => deleteStore(row.responseRegistrationOrder),
            classes: baseClasses.buttonsPrimary,
          },
        ],
      }),
    );
  };

  return (
    <TableRow className={classes.tableRow}>
      <TableCell>
        <Typography
          style={{ textAlign: 'center' }}
          className={baseClasses.small}
        >
          {row.responseRegistrationOrder}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{ height: 24, lineHeight: 2 }}
          className={`${baseClasses.small} ${classes.tableCellTxt}`}
        >
          {row.divisionName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          style={{ textAlign: 'center' }}
          className={classes.tableCellTxt}
        >
          <Radio
            name="nextRegist"
            value={row.divisionName}
            checked={Number(row.isNextRegistration)}
            disabled
          />
        </Typography>
      </TableCell>
      <TableCell>
        <Typography className={classes.tableCellTxt}>
          <Button
            className={classes.deleteButton}
            startIcon={
              <DeleteOutlineIcon className={classes.deleteOutlineIcon} />
            }
            onClick={confirmOpen}
            disabled={mode === NO_SETTING && registeredStores.length === 1}
          />
        </Typography>
      </TableCell>
    </TableRow>
  );
}

export default EditStoreTableRow;
