import { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import commonTheme from '../../styles/theme';
import EditStoreTable from './editStoreTable';
import commonStyles from '../../styles';
import SelectStoreWindow from '../selectStoreWindow';
import Dialog from '../../common/modal';
import { ABOVE_BUDGET_AMOUNT, BELOW_BUDGET_AMOUNT, NO_SETTING } from '../../../constants/combinationSetting';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#fff',
    color: '#333',
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    letterSpacing: 0,
    [commonTheme.breakpoints.down('sm')]: {
      padding: '0 16px',
    },
    '& .MuiTabs-flexContainer': {
      [commonTheme.breakpoints.down('sm')]: {
        justifyContent: 'space-between',
      },
    },
    '& .MuiTab-root': {
      minWidth: 'auto',
      padding: 8,
      [commonTheme.breakpoints.down('sm')]: {
        width: '20%',
      },
    },
    '& .MuiTab-root.Mui-selected': {
      color: theme.palette.primaryColor,
      fontWeight: '700',
    },
    '& .MuiTabs-indicator': {
      background: theme.palette.primaryColor,
    },
    '& .MuiTab-wrapper': {
      fontSize: 13,
    },
  },
  panel: {
    background: '#fff',
    position: 'absolute',
    top: 53,
    bottom: 24,
    left: 24,
    right: 24,
    overflowY: 'auto',
    padding: 10,
    marginTop: 10,
    border: '1px solid #C8C8C8',
  },
  icon: {
    fill: '#666',
    fontSize: 15,
  },
  contentBtn: {
    background: '#fff',
    borderRadius: 4,
    width: 148,
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 8px 7px',
    marginRight: 8,
    marginBottom: 8,
  },
  startIcon: {
    color: theme.palette.primaryColor,
    width: 16,
    height: 16,
    marginRight: 0,
  },
  addButton: {
    '& span': {
      marginRight: 0,
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      '&>span': {
        color: '#DEDEDE',
      },
    },
  },
  addButtonTxt: {
    color: theme.palette.primaryColor,
  },
}));

export default function Content(props) {
  const {
    mode,
    open,
    onSubmit,
    onClose,
    divisions1,
    divisions2,
    divisions3,
    areaCode,
  } = props;

  const classes = useStyles();
  const baseClasses = commonStyles();

  const [storeOpen, setStoreOpen] = useState(false);

  const [selectedList, setSelectedList] = useState([]);

  const onClick = useCallback(() => {
    onSubmit?.(selectedList);
    onClose?.();
  }, [selectedList]);

  const deleteStore = useCallback((order) => {
    // eslint-disable-next-line max-len
    const isNext = selectedList.find(e => e.responseRegistrationOrder === order).isNextRegistration === 1;
    const newArray = selectedList.filter(e => e.responseRegistrationOrder !== order);
    for (const e of newArray) {
      // 削除した分配列を詰める
      if (e.responseRegistrationOrder > order) {
        e.responseRegistrationOrder -= 1;
      }
    }
    if (newArray.length && isNext) {
      // 削除したものに次店舗フラグが付いていた場合、その次の組織に次店舗フラグをつける
      // 削除したものが配列の最後かどうかで分岐
      newArray.length >= order
        ? newArray[order - 1].isNextRegistration = 1
        : newArray[0].isNextRegistration = 1;
    }
    setSelectedList(newArray);
  }, [selectedList]);

  const buttons = useMemo(() => [
    {
      label: '選択',
      onClick,
    },
  ], [selectedList]);

  useEffect(() => {
    if (open) {
      switch (mode) {
        case ABOVE_BUDGET_AMOUNT:
          setSelectedList(divisions1 || []);
          break;
        case BELOW_BUDGET_AMOUNT:
          setSelectedList(divisions2 || []);
          break;
        case NO_SETTING:
          setSelectedList(divisions3 || []);
          break;
        default:
      }
    }
  }, [open]);

  return (
    <Dialog
      title="店舗設定ウィンドウ"
      open={open}
      onClose={onClose}
      buttons={buttons}
      minHeight="500px"
      maxWidth="640px"
    >
      <Grid>
        <Button
          className={classes.addButton}
          startIcon={<AddIcon className={`${classes.startIcon}`} />}
          onClick={() => setStoreOpen(true)}
        >
          <Typography
            className={`${baseClasses.smallStrong} ${classes.addButtonTxt}`}
          >
            追加
          </Typography>
        </Button>
      </Grid>
      <Grid>
        <Grid className={classes.panel}>
          <EditStoreTable
            mode={mode}
            registeredStores={selectedList}
            deleteStore={deleteStore}
          />
        </Grid>
      </Grid>
      <SelectStoreWindow
        open={storeOpen}
        setOpen={setStoreOpen}
        areaCode={areaCode}
        storeList={selectedList}
        setStoreList={setSelectedList}
      />
    </Dialog>
  );
}
