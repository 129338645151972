export const COMBINATION_HEADER_TH = [
  '表示順',
  '組合せ名',
  '希望地域',
  '予算設定なし店舗',
  '予算（万円以上）',
  '選択店舗',
  '予算（万円以下）',
  '選択店舗',
];

export const COMBINATION_HEADER_ID = [
  'displayOrder',
  'combinationName',
  'targetCityName',
  'storeGroupNames3',
  'price1',
  'storeGroupNames1',
  'price2',
  'storeGroupNames2',
];

export const ABOVE_BUDGET_AMOUNT = 1;
export const BELOW_BUDGET_AMOUNT = 2;
export const NO_SETTING = 3;

export const AMOUUT_MODE = {
  ABOVE_BUDGET_AMOUNT: '予算金額以上',
  BELOW_BUDGET_AMOUNT: '予算金額以下',
  NO_SETTING: '設定なし',
};

export const SETTING_TYPE = {
  1: '有効',
  0: '無効',
};
