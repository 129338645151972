import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { getEnvVariable } from './commonFunction/getEnv';

// OH本番環境のみdatadogを利用
if (getEnvVariable('DOMAIN') === 'ohg-sfa') {
  datadogRum.init({
    applicationId: '90bffd1f-fcfd-4c0a-8c7b-5bbb6cf77a3f',
    clientToken: 'pub266239c20b0b4eb0c46ca6db73aa15c6',
    // site refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'ap1.datadoghq.com',
    service: 'house-sfa',
    env: 'production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`https://api.${getEnvVariable('DOMAIN')}.openhouse-group.com`],
  });

  datadogLogs.init({
    clientToken: 'pub266239c20b0b4eb0c46ca6db73aa15c6',
    site: 'ap1.datadoghq.com',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });
}

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root'),
// );
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
