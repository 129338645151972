import AreaSentakuContainers from '../../../containers/common/areaSentaku/areaSentakuContainers';

function AreaSentaku(props) {
  const {
    initialParam,
    open,
    onClose,
    onUpdate,
    isFromSearch,
    isNoneWishAreaInit,
    isCombinationSetting,
  } = props;
  return (<AreaSentakuContainers
    initialParam={initialParam}
    open={open}
    onClose={onClose}
    onUpdate={onUpdate}
    isFromSearch={isFromSearch}
    isNoneWishAreaInit={isNoneWishAreaInit}
    isCombinationSetting={isCombinationSetting}
  />);
}

export default AreaSentaku;
