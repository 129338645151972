import client from '../httpClient';

/**
 * 【combination-setting002】組合せ設定詳細取得（組合せID指定）
 * @param {number} combinationId
 */
const combinationSettingGetListApi = async (combinationId) => {
  const res = await client.get(`/combination-settings/detail/${combinationId}`);
  return res.data;
};

export default combinationSettingGetListApi;
