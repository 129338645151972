import client from '../httpClient';

/**
 * 【combination-setting004】組み合わせ設定更新
 * @param {{
 * combinations: {
 * combinationId: number;
 * areaCode: number;
 * combinationName: string;
 * targetCityCode: string;
 * displayOrder: number;
 * settingType: number;
 * datails: {
 *   combinationTypeId: number;
 *   combinationType: number;
 *   price: number;
 *   divisions: {
 *     combinationDivisionId: number;
 *     responseRegistrationOrder: number;
 *     isNextRegistration: number;
 *     divisionId: number;
 *   }[];
 * }[];
 * }[];
 * }} data
 */
const combinationSettingPutApi = async (data) => {
  const res = await client.put('/combination-settings', { combinations: [data] });
  return res.data;
};

export default combinationSettingPutApi;
