import { useState, useEffect } from 'react';

import SelectStoreWindow from '../../../components/combinationSetting/selectStoreWindow/selectStoreWindow';
import { combinationSettingGetStoreApi } from '../../../apis/combinationSetting';

function SelectStoreWindowContainers(props) {
  const { open, setOpen, areaCode,
    initialStore = false, storeList = [], setStoreList } = props;

  // ここにAPIを記述

  const [divisions, setDivisions] = useState([]);

  const selectedStoreInitial = initialStore ? { divisionSubName2: initialStore } : {};
  const [selectedStore, setSelectedStore] = useState(selectedStoreInitial);

  /**
  * 【combination-setting006】組織情報取得（エリアコード指定）
  */
  const getDivisions = async () => {
    await combinationSettingGetStoreApi(areaCode)
      .then((res) => setDivisions(res.data.divisions))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (areaCode && open) getDivisions();
  }, [areaCode, open]);

  return (
    <SelectStoreWindow
      open={open}
      setOpen={setOpen}
      storeList={storeList}
      setStoreList={setStoreList}
      divisions={divisions}
      selectedStore={selectedStore}
      setSelectedStore={setSelectedStore}
    />
  );
}

export default SelectStoreWindowContainers;
