import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import commonTheme from '../../../styles/theme';
import UserTree from '../../userTreeNew';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #C8C8C8',
      background: '#fff',
      padding: '8px 8px 7px',
    },
    '&.disabled': {
      opacity: '0.4',
      pointerEvents: 'none',
    },
  },
  ttl: {
    width: 85,
    marginRight: 8,
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    flexShrink: 0,
    '& span': {
      color: '#D83420',
    },
  },
  btn: {
    fontFamily: 'Roboto',
    fontSize: 13,
    lineHeight: 1.6,
    fontWeight: '700',
    letterSpacing: 0,
    padding: 0,
    width: '100%',
    textAlign: 'left',
    minHeight: 20,
    '& .MuiButton-label': { display: 'block' },
    '&:hover': { backgroundColor: `${theme.palette.primaryColor}29` },
  },
  // SP
  list: {
    background: '#fff',
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listBtn: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 8px 16px 16px',
    borderBottom: '1px solid #F3F3F3',
    width: '100%',
  },
  listTtl: {
    fontSize: 13,
    lineHeight: 1.2,
    '& span': {
      color: '#D83420',
    },
  },
  listTxt: {
    fontSize: 13,
    fontWeight: 700,
    lineHeight: 1.2,
    textTransform: 'none',
  },
}));

function InfoRegisterChoice1(props) {
  const { values } = props;
  const classes = useStyles();

  const [manageWindowOpener, setManageWindowOpener] = useState(false); // FIXME*名前変える

  const initialUser = { userId: 0, userName: '' };
  const initialDivision = { divId: 0, divisionName: '' };
  // 組織ツリー選択ユーザー
  const [selectUser, setSelectUser] = useState(initialUser);
  // 組織ツリー選択組織
  const [selectDivision, setSelectDivision] = useState(initialDivision);

  // SP・PCでの分岐用
  const windowWidth = window.innerWidth;
  const breakPoint = 768;
  const isSP = (windowWidth < breakPoint);
  let contents;

  const onClickHandler = () => {
    setManageWindowOpener(!manageWindowOpener);
  };

  const handleSelectFunction = () => {
    if (selectUser.userId !== 0) {
      values.setState(selectUser);
      values.setStateDivision(selectDivision);
    } else {
      values.setState(values.selectUser);
      values.setStateDivision(values.selectDivision);
    }
  };
  const handleClearFunction = () => {
    values.setState(initialUser);
    values.setStateDivision(initialDivision);
  };

  // useEffect(() => {
  //   if (!manageWindowOpener && selectUser.userName) {
  //     values.setState(selectUser);
  //     values.setStateDivision(selectDivision);
  //   }
  // }, [manageWindowOpener, selectUser]);

  if (isSP) {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <List className={classes.list}>
          <ListItem className={classes.listItem}>
            <Button
              className={classes.listBtn}
              onClick={onClickHandler}
            >
              <Typography className={classes.listTtl}>
                {values.label}
                {values.required && <span>＊</span>}
              </Typography>
              <Typography className={classes.listTxt}>
                {values.state || ''}
              </Typography>
            </Button>
          </ListItem>
        </List>
      </Grid>
    );
  } else {
    contents = (
      <Grid
        className={`${classes.root} ${values.disabled ? 'disabled' : ''}`}
      >
        <Typography className={classes.ttl}>
          {values.label}
          {values.required && <span>＊</span>}
        </Typography>
        <Button
          className={classes.btn}
          onClick={onClickHandler}
        >
          {values.state}
        </Button>
      </Grid>
    );
  }

  return (
    <Grid>
      {contents}
      <UserTree
        isOpenTree={manageWindowOpener}
        setIsOpenTree={setManageWindowOpener}
        selectDivision={selectDivision}
        setSelectDivision={setSelectDivision}
        selectUser={selectUser.userName !== '' ? selectUser : values.selectUser}
        setSelectUser={setSelectUser}
        handleSelectFunction={handleSelectFunction}
        handleClearFunction={handleClearFunction}
        isDialog
        isSelectUserWindow
      />
    </Grid>
  );
}

export default InfoRegisterChoice1;
