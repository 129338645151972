import client from '../httpClient';

/**
 * 【combination-setting005】組み合わせ設定登録
 * @param {{
 * combinations: {
 * areaCode: number;
 * combinationName: string;
 * targetCityCode: string;
 * displayOrder: number;
 * settingType: number;
 * datails: {
 *   combinationType: number;
 *   price: number;
 *   divisions: {
 *     responseRegistrationOrder: number;
 *     divisionId: number;
 *   }[];
 * }[];
 * }[];
 * }} data
 */
const combinationSettingPostApi = async (data) => {
  const res = await client.post('/combination-settings', { combinations: [data] });
  return res.data;
};

export default combinationSettingPostApi;
