import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import commonTheme from '../../styles/theme';
import AreaSentakuTabs from './parts/areaSentakuTabs';
import CommonDialog from '../../common/modal';
import commonStyles from '../../styles';
import { useLoading } from '../../../hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    [commonTheme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 127px)',
    },
  },
  btnWrap: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 8,
    [commonTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  contentBtn: {
    background: '#fff',
    borderRadius: 4,
    width: 148,
    color: theme.palette.primaryColor,
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 15,
    lineHeight: 1.6,
    letterSpacing: 0,
    padding: '9px 8px 7px',
    marginRight: 8,
  },
  contentAll: {
    [commonTheme.breakpoints.up('md')]: {
      display: 'none',
    },
    background: '#fff',
    position: 'absolute',
    top: 65,
    left: '0',
    width: '100%',
    padding: '0 0 0 16px',
    '&.lower': {
      display: 'none',
    },
    '& .MuiFormControlLabel-root': {
      margin: 0,
      justifyContent: 'space-between',
      width: '100%',
      borderBottom: '1px solid #F3F3F3',
      padding: '12px 11px 12px 16px',
      '& .Mui-checked': {
        color: theme.palette.primaryColor,
      },
      '& .Mui-checked + .MuiTypography-root': {
        color: theme.palette.primaryColor,
      },
      '& .MuiSvgIcon-root': {
        fontSize: 22,
      },
    },
    '& .Mui-checked': {
      color: theme.palette.primaryColor,
    },
    '& .Mui-checked + .MuiTypography-root': {
      color: theme.palette.primaryColor,
    },
    '& .MuiSvgIcon-root': {
      fontSize: 22,
    },
    '& .MuiCheckbox-root': {
      padding: '5px 7px 5px 9px',
      order: 2,
    },
    '& .MuiTypography-body1': {
      fontFamily: 'Roboto',
      fontSize: 16,
      lineHeight: 1.2,
      letterSpacing: 0,
      marginTop: 2,
      order: 1,
      fontWeight: 700,
    },
  },
  dialog: {
    '& .MuiDialogContent-root': {
      [commonTheme.breakpoints.down('sm')]: {
        paddingBottom: 0,
      },
    },
  },
}));

export default function AreaSentakuWrapper(props) {
  const {
    areaTabIndex,
    areas,
    open,
    onClose,
    onSelect,
    onChangeArea,
    onChangeChecked,
    isNoneWishArea,
    setIsNoneWishArea,
    isFromSearch,
    isCombinationSetting,
  } = props;
  const { hasLoading } = useLoading();
  const baseClasses = commonStyles();
  const classes = useStyles();

  const isSp = useSelector((state) => state.deviceTypeSlice.isSp);

  // const [all, setAll] = useState(0);

  // すべてチェック or すべて選択解除
  const allCheckOrLift = (checked) => {
    const currentArea = areas[areaTabIndex];
    currentArea.val.forEach((val, i) => {
      val.state.data.areas.forEach((valAreas) => {
        onChangeChecked({
          id: valAreas.id,
          index: i,
          label: valAreas.city,
          cityJisCode: valAreas.cityJisCode,
          checked,
        });
      });
    });
    setIsNoneWishArea(checked);
  };

  // 全て選択
  const allCheck = () => allCheckOrLift(true);
  const allLift = () => allCheckOrLift(false);

  // SP すべてチェック
  const onChangeAll = (e) => {
    if (e.target.checked) {
      allCheck();
    } else {
      allLift();
    }
  };

  // 希望地域無しがチェックされたとき
  const onSelectNoneChecked = (e) => {
    setIsNoneWishArea(e.target.checked);
  };

  // チェックボックスの地域全体のチェック状態を取得
  const isCheckedAll = (param) => {
    const currentArea = areas[param.areaTabIndex];
    if (!currentArea?.loaded) {
      return false;
    }
    const valAreas = currentArea.val.map((val) => {
      return val.state.data.areas;
    }).flat();
    const wishAreas = currentArea.wish;
    return valAreas.length === wishAreas.length;
  };

  // SP 下層 表示制御
  const [lower, setLower] = useState(false);

  const body = (
    <Grid className={`${classes.root} ${isSp && hasLoading('Area') ? baseClasses.loading : ''}`}>
      {!isCombinationSetting && (
        <Grid className={classes.btnWrap}>
          <Button
            className={classes.contentBtn}
            onClick={allCheck}
            disabled={hasLoading('Area')}
          >
            すべてチェック
          </Button>
          <Button
            className={classes.contentBtn}
            onClick={allLift}
            disabled={hasLoading('Area')}
          >
            すべて選択解除
          </Button>
        </Grid>
      )}
      <Grid className={`${classes.contentAll} ${lower && 'lower'}`}>
        <FormControlLabel
          control={
            <Checkbox
              checked={isCheckedAll({ areaTabIndex })}
              onChange={onChangeAll}
            />
          }
          label="すべて"
        />
        {isFromSearch && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isNoneWishArea}
                onChange={onSelectNoneChecked}
                color="primary"
              />
            }
            label="希望地域無し"
          />
        )}
      </Grid>
      <AreaSentakuTabs
        areaTabIndex={areaTabIndex}
        areas={areas}
        lower={lower}
        setLower={setLower}
        onChangeArea={onChangeArea}
        onChangeChecked={onChangeChecked}
        onSelectNoneChecked={onSelectNoneChecked}
        isNoneWishArea={isNoneWishArea}
        isFromSearch={isFromSearch}
        isCombinationSetting={isCombinationSetting}
      />
    </Grid>
  );

  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title={isSp ? '地域選択' : '地域選択ウィンドウ'}
      maxWidth={1028}
      className={classes.dialog}
      buttons={[
        {
          label: '全クリア',
          className: baseClasses.onlySp,
          onClick: () => {
            allLift();
            setLower(false);
          },
          type: 'secondary',
          disabled: hasLoading('Area'),
        },
        {
          label: isSp ? 'OK' : '選択',
          onClick: () => {
            onClose();
            onSelect();
            if (isSp) {
              setLower(false);
            }
          },
          disabled: hasLoading('Area'),
        },
      ]}
    >
      {body}
    </CommonDialog>
  );
}
