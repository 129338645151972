import StoreGroupsDialogContainer from '../../../containers/combinationSetting/storeGroupsDialog/storeGroupsDialogContainer';

export default function StoreGroupsDialog(props) {
  const {
    open,
    onClose,
    mode,
    onSubmit,
    divisions1,
    divisions2,
    divisions3,
    areaCode,
  } = props;

  return (
    <StoreGroupsDialogContainer
      mode={mode}
      open={open}
      onClose={onClose}
      onSubmit={onSubmit}
      divisions1={divisions1}
      divisions2={divisions2}
      divisions3={divisions3}
      areaCode={areaCode}
    />
  );
}
