/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { makeStyles, Table, TableBody, TableContainer } from '@material-ui/core';
import Row from './row';
import { useCombinationSettingContext } from '../../../containers/combinationSetting';
import TableHeaders from './tableHeaders';
import { COMBINATION_HEADER_ID } from '../../../constants/combinationSetting';

const useStyles = makeStyles({
  tableSearchRow: {
    background: '#F3F3F3',
  },
  tableSearchRowCell: {
    margin: '0px',
    padding: '4px',
  },
  menuItem: {
    padding: '6px',
  },
});

const filteredBySearch = (row, searchParam) => {
  const checkFunc = (key) => {
    const searchValue = RegExp(searchParam[key]);
    return searchParam[key] ? searchValue.test(row[key]) : true;
  };

  return COMBINATION_HEADER_ID.every((checkKey) => checkFunc(checkKey));
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function ListTable() {
  const {
    list,
    deleteApi,
    getListApi,
    currentArea,
  } = useCombinationSettingContext();

  const classes = useStyles();

  const [searchParam, setSearchParam] = useState({});

  useEffect(() => {
    currentArea.areaCode && getListApi(currentArea.areaCode);
  }, [currentArea.areaCode]);

  // 並び替え
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('displayOrder');
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table>
        <TableHeaders
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          setSearchParam={setSearchParam}
        />
        <TableBody className={classes.tableBody}>
          {stableSort(list, getComparator(order, orderBy)).map((row) => {
            return filteredBySearch(row, searchParam) ? (
              <Row
                row={row}
                key={`${row.displayOrder} ${row.combinationName}`}
                onDelete={() => {
                  deleteApi({
                    combinationId: row.combinationId,
                    areaCode: row.areaCode,
                  });
                }}
              />
            ) : null;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
