import {
  createContext,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import {
  combinationSettingGetListApi,
  combinationSettingGetDetailApi,
  combinationSettingDeleteApi,
  combinationSettingPutApi,
  combinationSettingPostApi,
} from '../../apis/combinationSetting';
import { useLoading } from '../../hooks';
import { useCityJisCodes } from '../../components/combinationSetting/common/useCityJisCodes';

// CombinationSetting Context
const CombinationSettingContext = createContext({});

/**
 * CombinationSettingProvider
 * @param {*} props
 * @returns {Provider}
 */
export function CombinationSettingProvider(props) {
  const { children } = props;

  // 【settingGroupStore008】エリアリスト取得
  // { areaCode: number, areaName: string, wishCityCount: number }
  const { areaList: areaOptions } = useCityJisCodes();

  // ローディング
  const { addLoading, removeLoading } = useLoading();

  // 現在選択中のエリア
  const [currentArea, setCurrentArea] = useState({});

  // 連打防止フラグ
  const isProcessing = useRef(false);

  // 【combination-setting001】組合せ設定一覧取得（エリアコード指定）
  const [list, setList] = useState([]);

  /**
   * 【combination-setting001】組合せ設定一覧取得（エリアコード指定）
   */
  const getListApi = useCallback(async (areaCode) => {
    addLoading();
    await combinationSettingGetListApi(areaCode)
      .then((res) => setList(res || []))
      .finally(() => removeLoading());
  }, []);

  /**
   * 【combination-setting002】組合せ設定詳細取得（組合せID指定）
   */
  const getDetailApi = useCallback(async (id) => {
    addLoading();
    const res = await combinationSettingGetDetailApi(id)
      .finally(() => removeLoading());
    return res;
  }, []);

  /**
   * 【combination-setting003】組み合わせ設定削除
   */
  const deleteApi = useCallback(async (params) => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    addLoading();
    await combinationSettingDeleteApi(params)
      .then(() => getListApi(currentArea.areaCode))
      .finally(() => {
        removeLoading();
        isProcessing.current = false;
      });
  }, [currentArea]);

  /**
   * 【combination-setting004】組み合わせ設定更新
   */
  const updateApi = useCallback(async (params, successCallback = () => { }) => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    addLoading();
    await combinationSettingPutApi(params)
      .then(async () => {
        await getListApi(currentArea.areaCode);
        successCallback?.();
      })
      .finally(() => {
        removeLoading();
        isProcessing.current = false;
      });
  }, [currentArea]);

  /**
   * 【combination-setting005】組み合わせ設定登録
   */
  const registerApi = useCallback(async (params, successCallback = () => { }) => {
    if (isProcessing.current) return;
    isProcessing.current = true;
    addLoading();
    await combinationSettingPostApi(params)
      .then(async () => {
        await getListApi(currentArea.areaCode);
        successCallback?.();
      })
      .finally(() => {
        removeLoading();
        isProcessing.current = false;
      });
  }, [currentArea]);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const value = {
    // select options
    areaOptions,
    // condition state
    currentArea,
    setCurrentArea,
    // 一覧情報
    list,
    // 取得
    getListApi,
    getDetailApi,
    // 削除
    deleteApi,
    // 新規・編集
    registerApi,
    updateApi,
  };

  return (
    <CombinationSettingContext.Provider value={value}>
      {children}
    </CombinationSettingContext.Provider>
  );
}

export function useCombinationSettingContext() {
  return useContext(CombinationSettingContext);
}
