import client from '../httpClient';

/**
 * 【combination-setting006】組織情報取得（エリアコード指定）
 * @param {{
 * areaCode: number;
 * }} data
 * @returns Promise<*>
 */
const combinationSettingGetStoreApi = async (areaCode) => {
  const res = await client.get(`/combination-settings/store/${areaCode}`);
  return res;
};

export default combinationSettingGetStoreApi;
